import axios from "axios"

import Utils from '../../lib/utils'
import NiceI18n from '../../lib/nice_i18n'

export default {
  namespaced: true,
  state: {
    savedOrders: [],
    currentOrder: null,

    formattedTotalPrice: 0,
    formattedTotalDiscountedPrice: 0,
    formattedTotalQuantity: 0,
    listPageUrl: ''
  },
  getters: {},
  mutations: {
    SET_ORDERS: (state, orders) => {
      state.savedOrders = orders
    },
    SET_CURRENT_ORDER: (state, order) => {
      state.currentOrder = JSON.parse(JSON.stringify(order))
    },
    UPDATE_CURRENT_ORDER_LINE_ITEM: (state, lineItem) => {
      const lineItems = state.currentOrder.line_items.map(item => {
        return (item.id == lineItem.id) ? lineItem : item
      })

      state.currentOrder = {
        ...state.currentOrder,
        line_items: lineItems
      }
    },
    REMOVE_CURRENT_ORDER_LINE_ITEM: (state, lineItemId) => {
      const lineItems = state.currentOrder.line_items.filter(line_item => line_item.id !== lineItemId)

      state.currentOrder = {
        ...state.currentOrder,
        line_items: lineItems
      }
    },
    SET_FORMATTED_TOTALS: (state, totals) => {
      state.formattedTotalPrice = totals.price
      state.formattedTotalDiscountedPrice = totals.discountedPrice
      state.formattedTotalQuantity = totals.quantity
    },
    SET_LIST_PAGE_URL: (state, url) => {
      if (state.listPageUrl !== url) {
        state.listPageUrl = url
      }
    }
  },
  actions: {
    initQuickOrder: async ({ commit, dispatch, rootGetters }) => {
      try {
        // Inits a non-persisted saved order from API
        const reqPath = `${rootGetters.apiPath}/saved_orders/new`
        const { data } = await axios.get(reqPath)
        const order = await dispatch('handleCustomAttributes', data.saved_order)

        commit('SET_CURRENT_ORDER', {
          ...order,
          isQuickOrder: true
        })
      } catch (error) {
        console.error('Error initializing order:', error)
      }
    },
    loadSavedOrder: async ({ commit, dispatch, rootGetters }, savedOrderId) => {
      try {
        const reqPath = `${rootGetters.apiPath}/saved_orders/${savedOrderId}/edit`
        const { data } = await axios.get(reqPath)
        const order = await dispatch('handleCustomAttributes', data.saved_order)

        dispatch('triggerEvent', 'gy::saved-order-loaded', { root: true })
        commit('SET_CURRENT_ORDER', order)
      } catch (error) {
        console.error('Error loading saved order:', error)
      }
    },
    loadSavedOrders: async ({ commit, dispatch, rootGetters }) => {
      const reqPath = `${rootGetters.apiPath}/saved_orders`
      const res = await axios.get(reqPath)

      commit('SET_ORDERS', res.data.saved_orders)
      dispatch('triggerEvent', 'gy::saved-orders-loaded', { root: true })
    },

    createOrder: async ({ commit, dispatch, rootGetters }, { order }) => {
      const ORDER_TYPE_ID = 4 // TODO: what is this type hard coded ?

      let errors = null
      const reqPath = `${rootGetters.apiPath}/saved_orders`
      const products = await dispatch('formatLineItemsForPost', order.line_items)
      const requestData = {
        order_type_id: ORDER_TYPE_ID,
        saved_order: { title: order.title },
        products
      }

      try {
        const { data } = await axios.post(reqPath, requestData)

        if (data.errors) {
          errors = data.errors
          const eventDataOrderNotCreated = { type: 'gy::saved-order-not-created', message: this.errors }
          dispatch('triggerEvent', eventDataOrderNotCreated, { root: true })
        } else {
          commit('SET_CURRENT_ORDER', {
            ...order,
            id: data.saved_order.id
          })
          const eventDataOrderCreated = { type: 'gy::saved-order-created', message: data.message }
          dispatch('triggerEvent', eventDataOrderCreated, { root: true })

          if (order.isQuickOrder) {
            // TODO: Maybe trigger an event 'gy:saved-order-quick-order-created'
          }
        }
      } catch (error) {
        console.error(`Error creating saved order:`, error)
      }

      return { errors }
    },
    updateOrder: async ({ commit, dispatch, rootGetters }, { order }) => {
      const ORDER_TYPE_ID = 4 // TODO: what is this type hard coded ?

      let errors = null
      const reqPath = `${rootGetters.apiPath}/saved_orders/${order.id}/add_to_cart`
      const products = await dispatch('formatLineItemsForPost', order.line_items)
      const requestData = {
        update_saved_order: "true",
        order_type_id: ORDER_TYPE_ID,
        addition: "false",
        title: order.title,
        products
      }

      try {
        const { data } = await axios.post(reqPath, requestData)

        if (data.errors) {
          errors = data.errors
          const eventDataOrderNotCreated = { type: 'gy::saved-order-quick-not-updated', message: this.errors }
          dispatch('triggerEvent', eventDataOrderNotCreated, { root: true })
        } else {
          commit('SET_CURRENT_ORDER', order)

          const eventDataOrderCreated = { type: 'gy::saved-order-updated', message: data.message }
          dispatch('triggerEvent', eventDataOrderCreated, { root: true })
        }
      } catch (error) {
        console.error(`Error updating saved order:`, error)
      }

      return { errors }
    },
    destroyOrder: async ({ dispatch, rootGetters }, orderId) => {
      const confirmPrompt = confirm(NiceI18n.t('quick_order.saved_order.prompt_to_delete'))
      const reqPath = `${rootGetters.apiPath}/saved_orders/${orderId}`

      if (confirmPrompt) {
        try {
          const { data } = await axios.delete(reqPath)

          const eventDataOrderDeleted = {
            type: 'gy::saved-order-deleted',
            message: data.message
          }
          dispatch('loadSavedOrders')
          dispatch('triggerEvent', eventDataOrderDeleted, { root: true })
          dispatch('redirectToOrderListPage')
        } catch (error) {
          console.error('Error deleting saved order:', error)
        }
      }
    },
    calculateDisplayOrder: ({ commit }, { lineItems }) => {
      let total_price = 0.0
      let total_discounted_price = 0.0
      let total_quantity = 0
      let discounted_price = 0.0

      for (let i = 0; i < lineItems.length; i++) {
        // check for new element that does not have a discounted price
        discounted_price = lineItems[i].discounted_price
        if (discounted_price == null) continue

        total_price += Utils.formatPriceForCalc(discounted_price, window.current_currency.separator, window.current_currency.decimal_mark, window.current_currency.symbol)
        total_discounted_price += Utils.formatPriceForCalc(lineItems[i].total_discounted_price, window.current_currency.separator, window.current_currency.decimal_mark, window.current_currency.symbol)
        total_quantity += parseInt(lineItems[i].quantity)
      }

      const totals = {
        price: Utils.formatCurrency(total_price, window.current_currency.decimal_mark, window.current_currency.symbol),
        discountedPrice: Utils.formatCurrency(total_discounted_price, window.current_currency.decimal_mark, window.current_currency.symbol),
        quantity: total_quantity
      }
      commit('SET_FORMATTED_TOTALS', totals)
    },
    addOrderToCartById: async ({ dispatch, rootGetters }, orderId) => {
      const isAlreadyConfirmed = document.getElementById('gy-saved-orders')
      const confirmMessage = NiceI18n.t('quick_order.saved_order.add_to_cart_prompt')
      const confirmPrompt = isAlreadyConfirmed || confirm(confirmMessage)

      const reqPath = `${rootGetters.apiPath}/saved_orders/${orderId}/add_to_cart`

      if (confirmPrompt) {
        try {
          const res = await axios.post(reqPath, {
            add_saved_order_to_cart: "true"
          })

          const eventDataAddedToCart = {
            type: 'gy::saved-order-added-to-cart',
            message: res.data.message
          }
          dispatch('loadOrder', null, { root: true })
          dispatch('triggerEvent', eventDataAddedToCart, { root: true })
        } catch (error) {
          console.error('Error adding saved order to cart:', error)
        }
      }
    },
    addQuickOrderToCartByProducts: async ({ dispatch, rootGetters }, lineItems) => {
      try {
        const reqPath = `${rootGetters.apiPath}/order`
        const products = await dispatch('formatLineItemsForPost', lineItems)
        const { data } = await axios.post(reqPath, { products })

        if (data.error) {
          const eventDataNotAddedToCart = {
            type: 'gy::saved-order-quick-not-added-to-cart',
            message: data.message
          }
          dispatch('triggerEvent', eventDataNotAddedToCart, { root: true } )
        } else {
          const eventDataAddedToCart = {
            type: 'gy::saved-order-quick-added-to-cart',
            message: data.message
          }
          dispatch('loadOrder', null, { root: true })
          dispatch('triggerEvent', eventDataAddedToCart, { root: true })
        }
      } catch (error) {
        console.error('Error adding quick order to cart:', error)
      }
    },

    /**
     * Construct the "products" param that will be posted in update()
     * @param {*} _
     * @param {*} lineItems
     * @returns Formated line items
     */
    formatLineItemsForPost(_, lineItems) {
      return lineItems.map((currItem) => {
        const { product_id, options, quantity, custom_attributes } = currItem

        return {
          product_id,
          options,
          quantity,
          line_item_attributes: custom_attributes
        }
      })
    },
    /*
     * Adds, removes or manipulates custom attributes
     * @param {savedOrder} the saved order to manipulate
     */
    handleCustomAttributes (_, order) {
      const lineItems = order.line_items.map(lineItem => ({
        ...lineItem,
        is_checked: false
      }))

      return {
        ...order,
        line_items: lineItems,
        check_all: false
      }
    },

    redirectToOrderListPage({ state }) {
      if (state.listPageUrl) {
        setTimeout(() => {
          window.location = state.listPageUrl
        }, 1500)
      }
    }
  }
}