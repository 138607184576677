export default {
  quick_order: {
    no_saved_orders: 'You have no saved orders',
    saved_order: {
      add_to_cart_prompt: "Only saved changes will be applied to cart. Do you want to proceed?",
      default_title: "Without title",
      no_line_items_on_save: "Save was unsuccessful. The order has zero products.",
      prompt_to_delete: "Are you sure you want to delete this order?"
    },
    form: {
      add_new_product: 'add new product',
      add_to_cart: 'Add to cart',
      code: 'Order code',
      edit: 'Edit',
      save: 'Save order',
      table: {
        barcode: 'Barcode',
        final_cost: 'Final cost',
        code: 'ORDER CODE',
        order_title: 'ORDER TITLE',
        price_per_product: 'UNIT PRICE',
        product: 'PRODUCT',
        quantity: 'QUANTITY',
        total: 'TOTAL'
      },
      title: 'My order title'
    }
  }
}