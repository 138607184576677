import Vue              from 'vue'
import VueResource      from 'vue-resource'
import { mapGetters }   from 'vuex'
import { store }        from '../store'
import savedOrdersMixin from '../mixins/saved_orders_mixin'

Vue.use(VueResource)

const el = document.getElementById("gy-saved-orders")

if (el) {
  new Vue({
    el: el,
    components: {
      SavedOrder: () => import('../components/saved_orders/SavedOrder.vue')
    },
    mixins: [savedOrdersMixin],
    data: {
      savedOrders: []
    },
    store,
    computed: {
      ...mapGetters([
        'apiPath'
      ])
    },
    mounted() {
      if (this.$el.id.length > 0) {
        this.loadSavedOrders()
      }
    }
  })
}
