<template>
  <QuickOrder
    v-if="currentOrder"
    :list-page-url="listPageUrl"
    :no-default-title="false"
    open-new-line-item-on-startup
  >
    <template slot="tableHeader">
      <QuickOrderTableHeader
        :display-column-keys="['product', 'quantity', 'pricePerProduct', 'total', 'actions']"
        :classes="mergedClassNames"
      />
    </template>
  </QuickOrder>
</template>

<script>
import { merge } from 'lodash'
import { mapGetters, mapState } from "vuex"

import QuickOrder from "./QuickOrder.vue"
import QuickOrderTableHeader from './QuickOrderTable/QuickOrderTableHeader.vue'

import NiceI18n from '../../lib/nice_i18n'
import quickOrderDefaultClasses from './defaultClasses'


export default {
  name: "QuickOrderAddOrEdit",
  components: {
    QuickOrder,
    QuickOrderTableHeader
  },
  provide() {
    return {
      autofocus: this.autofocus,
      classes: this.mergedClassNames
    }
  },
  props: {
    savedOrderId: {
      type: String,
      default: null
    },
    listPageUrl: {
      type: String,
      default: ""
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    classes: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      NiceI18n
    }
  },
  computed: {
    ...mapState('quick_order', ['currentOrder']),
    ...mapGetters(["apiPath"]),
    mergedClassNames() {
      return merge(quickOrderDefaultClasses, this.classes?.quickOrder)
    }
  },
  mounted() {
    this.fetchOrderDetails()
  },
  methods: {
    async fetchOrderDetails() {
      if (this.savedOrderId) {
        this.$store.dispatch('quick_order/loadSavedOrder', this.savedOrderId)
      } else {
        this.$store.dispatch('quick_order/initQuickOrder')
      }

      if (this.listPageUrl) {
        this.$store.commit('quick_order/SET_LIST_PAGE_URL', this.listPageUrl)
      }
    }
  }
}
</script>