import Vue from 'vue'
import VueResource from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import { store } from '../../store'

Vue.use(VueResource)

const elements = document.getElementsByClassName('gy-b2b-offers')

for (let el of elements) {
  new Vue({
    el: el,
    store,
    components: {
      B2bOffer: () => import('../../components/b2b/offer.vue'),
      B2bOfferCartOrder: () => import('../../components/b2b/offer/cart_order.vue'),
      B2bOfferOrder: () => import('../../components/b2b/offer/order.vue'),
      B2bOfferLineItem: () => import('../../components/b2b/offer/line_item.vue'),
      B2bOfferItem: () => import('../../components/b2b/offer/item.vue')
    },
    computed: {
      ...mapState([
        'offerOrder'
      ]),
      ...mapGetters([
        'apiPath',
        'siteLocalePath'
      ]),
      isCartEnabled() {
        return this.totalDiscountedPrice > 0
      },
      customAttributes() {
        return (this.$el.attributes['custom-attributes'] || {}).value
      },
      cartEmpty() {
        return this.offerOrder.total_quantity == undefined || this.offerOrder.total_quantity < 1
      },
      cartTotalQuantity() {
        return this.offerOrder && this.offerOrder.total_quantity
      }
    },
    mounted() {
      if (el.attributes && el.attributes.versions)
        this.$store.commit('setOfferOrderVersions', this.$el.attributes.versions.value)

      this.$store.dispatch('loadOfferOrder')
    }
  })
}
