<template>
  <div :class="mergedClassNames.quickOrderContainer">
    <QuickOrderTableHeader
      :classes="mergedClassNames"
    />

    <div
      v-if="savedOrders.length === 0"
      :class="mergedClassNames.quickOrderNoResults"
    >
      {{ NiceI18n.t("quick_order.no_saved_orders") }}
    </div>

    <div v-else>
      <LineItemQuickOrder
        v-for="(orderItem) in savedOrders"
        :key="orderItem.id"
        :order="orderItem"
      />
    </div>
  </div>
</template>

<script>
import { merge } from 'lodash'
import { mapGetters, mapState } from "vuex"

import QuickOrderTableHeader from './QuickOrderTable/QuickOrderTableHeader.vue'
import LineItemQuickOrder from './LineItem/LineItemQuickOrder.vue'

import NiceI18n from '../../lib/nice_i18n'
import quickOrderDefaultClasses from './defaultClasses'

export default {
  name: "QuickOrderList",
  components: {
    QuickOrderTableHeader,
    LineItemQuickOrder
  },
  provide() {
    return {
      classes: this.mergedClassNames
    }
  },
  props: {
    classes: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      NiceI18n
    }
  },
  computed: {
    ...mapState('quick_order', ['savedOrders']),
    ...mapGetters(["apiPath"]),
    mergedClassNames() {
      return merge(quickOrderDefaultClasses, this.classes?.quickOrder)
    }
  },
  mounted() {
    this.loadSavedOrders()
  },
  methods: {
    loadSavedOrders() {
      this.$store.dispatch('quick_order/loadSavedOrders')
    }
  }
}
</script>