<template>
  <ul :class="classes.quickOrderTable.bodyRow">
    <!-- Product Title -->
    <li
      :class="[
        classes.quickOrderTable.columns.product.align,
        classes.quickOrderTable.columns.product.body.container
      ]"
    >
      <QuickOrderProductSelect
        :class="classes.quickOrderTable.columns.product.body.productSelect"
        :attr-id="item.product_id || Date.now()"
        :selected-product-id="item.id"
        :per="10"
        :placeholder="selectPlaceholder"
        :autofocus="!autofocus && lineItemIndex === 0"
        @gy:saved-line-item-product-changed="fetchAndEmitProductInfo"
      >
        <div
          slot="content"
          slot-scope="slotProps"
          class="result-wrapper"
        >
          <div class="title text-sm mb-1">
            {{ slotProps.option.title }}
          </div>
          <div class="code text-sm font-bold">
            <span :class="classes.quickOrderTable.label">
              {{ NiceI18n.t("quick_order.form.table.code") }}
            </span>
            {{ slotProps.option.code }}
          </div>
          <div
            v-if="slotProps.option.options"
            class="groups-wrapper"
          >
            <span
              v-for="(variant, option, index) in slotProps.option.options"
              :key="option"
              class="group"
            >
              <span class="option">{{ option }}:</span>
              <span class="variant">{{ variant }}</span>
              <span
                v-if="Object.keys(slotProps.option.options).length != index + 1"
                class="separator"
              >-</span>
            </span>
          </div>
        </div>
      </QuickOrderProductSelect>

      <div :class="classes.quickOrderTable.columns.product.body.titleWrapper">
        <div v-if="item.barcode">
          <span :class="classes.quickOrderTable.label">
            {{ NiceI18n.t("quick_order.form.table.barcode") }}
          </span>
          {{ item.barcode }}
        </div>
      </div>
    </li>

    <!-- Quantity -->
    <li
      :class="[
        classes.quickOrderTable.columns.quantity.align,
      ]"
    >
      <div
        v-if="item.product_id"
        :class="[classes.quickOrderTable.columns.quantity.body.container]"
      >
        <span :class="classes.quickOrderTable.label">
          {{ NiceI18n.t("quick_order.form.table.quantity") }}
        </span>
        <div
          v-if="item.quantity_step > 0"
          class="mt-2"
        >
          <div class="flex flex-col">
            <div class="select-wrap p-0">
              <select
                id="saved-order-quantity"
                v-model="lineItemQuantity"
                :class="classes.quickOrderTable.columns.quantity.body.select"
              >
                <option
                  v-for="(q, index) in quantities"
                  :key="index"
                  :value="q"
                >
                  {{ q }} {{ item.custom_attributes.measurement_unit_title }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          v-else
          :class="classes.quickOrderTable.columns.quantity.body.inputButtons.container"
        >
          <button
            :class="classes.quickOrderTable.columns.quantity.body.inputButtons.decreaseBtn"
            @click="decreaseQuantity()"
          >
            -
          </button>
          <input
            v-model="lineItemQuantity"
            :class="classes.quickOrderTable.columns.quantity.body.inputButtons.input"
            type="number"
          >
          <button
            :class="classes.quickOrderTable.columns.quantity.body.inputButtons.increaseBtn"
            @click.prevent="increaseQuantity()"
          >
            +
          </button>
        </div>
        <strong
          v-if="item.custom_attributes.measurement_unit"
          class="text-center sm:text-left md:text-center"
        >
          {{ item.custom_attributes.measurement_unit.name }}
        </strong>
      </div>
    </li>

    <!-- Price per Product -->
    <li
      :class="[
        classes.quickOrderTable.columns.pricePerProduct.align,
        classes.quickOrderTable.columns.pricePerProduct.body.container
      ]"
    >
      <strong class="md:hidden text-left">
        {{ NiceI18n.t("quick_order.form.table.price_per_product") }}
      </strong>
      <del
        v-if="item.offered"
        class="opacity-60 pr-2"
      >
        {{ item.price }}
      </del>
      <span class="text-2xl">
        {{ item.discounted_price }}
      </span>
    </li>

    <!-- Total -->
    <li
      :class="[
        classes.quickOrderTable.columns.total.align,
        classes.quickOrderTable.columns.total.body.container
      ]"
    >
      <strong class="md:hidden text-left">
        {{ NiceI18n.t("quick_order.form.table.total") }}
      </strong>
      <strong class="text-2xl">
        {{ item.total_discounted_price }}
      </strong>
    </li>

    <!-- Actions -->
    <li
      :class="[
        classes.quickOrderTable.columns.actions.align,
        classes.quickOrderTable.columns.actions.body.container
      ]"
    >
      <button
        :class="classes.quickOrderTable.columns.actions.body.remove"
        @click="emitDelete"
      >
        <span class="hidden">Delete item</span>
        <CloseSvgIcon />
      </button>
    </li>
  </ul>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapState } from 'vuex'

import QuickOrderProductSelect from '../QuickOrderProductSelect.vue'
import CloseSvgIcon from '../../layout/icon/CloseSvgIcon.vue'

import NiceI18n from '../../../lib/nice_i18n'

export default ({
  name: 'QuickOrderLineItem',
  components: {
    QuickOrderProductSelect,
    CloseSvgIcon
  },
  inject: ['autofocus', 'classes'],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    lineItemIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      NiceI18n,
      timeoutId: 0,
      lineItemQuantity: this.item.quantity
    }
  },
  computed: {
    ...mapState('quick_order', ['currentOrder']),
    ...mapGetters([
      'apiPath'
    ]),
    quantities() {
      /*
      * Creates an range array from 1...this.maxQuantity, with steps based on
      * item.quantity_step. If minimum purchase quantity is 10 it will create
      * options based on steps of 10.
      */
      const step = (this.quantityStep > 0) ? this.quantityStep : 1
      const length = Math.floor(this.item.max_quantity / step)

      return Array(length || 0).fill().map((_, i) => (i + 1) * step)
    },
    quantityStep() {
      return this.item.quantity_step || 0
    },
    selectPlaceholder() {
      if (this.item == undefined || this.item.code == undefined)
        return undefined

      return `${this.item.code} - ${this.item.title}`
    }
  },
  watch: {
    'lineItemQuantity': function (newQuantity, _) {
      const newQt = parseInt(newQuantity)

      if (newQt && newQt > 0) {
        if (newQt > this.item.max_quantity) {
          this.lineItemQuantity = this.item.max_quantity
        }

        clearTimeout(this.timeoutId)

        this.timeoutId = setTimeout(() => {
          const obj = {
            productId: this.item.product_id,
            isQuantityUpdate: true
          }

          if (this.item.sku) obj.skuId = this.item.sku.id

          this.fetchAndEmitProductInfo(obj)
        }, 200)
      } else if (!isNaN(newQt)) {
        this.updateQuantity(1)
      }
    },
    'item.is_checked': function (newValue, oldValue) {
      this.$emit('handleLineItemCheck')
    }
  },
  methods: {
    async fetchAndEmitProductInfo(obj) {
      if (this.productIsAlreadyAdded(obj) && this.lineItemQuantity > 0) {
        try {
          const res = await axios.get(`${this.apiPath}/line_items/build`, {
            params: {
              product_id: obj.productId,
              sku_id: obj.skuId,
              quantity: this.lineItemQuantity
            }
          })

          const lineItem = {
            ...res.data.line_item,
            id: this.item.id,
            is_checked: this.item.is_checked
          }

          this.$emit('updateLineItemEl', lineItem)
        } catch (error) {
          console.error('Error fetching product information:', error)
        }
      }
    },
    productIsAlreadyAdded(obj) {
      if (obj.isQuantityUpdate) {
        return true
      }

      const sameProductIds = this.currentOrder.line_items.filter((lineItem) => {
        const isProductIdSame = lineItem.product_id === obj.productId
        const isSkuCheckPassed = !lineItem.sku || (lineItem.sku && lineItem.sku.id === obj.skuId)
        return isProductIdSame && isSkuCheckPassed
      })

      const isLineItemAlreadyExists = this.currentOrder.line_items.length > 1 && sameProductIds.length > 0

      if (isLineItemAlreadyExists) {
        const lineItems = this.currentOrder.line_items
        lineItems.shift()

        this.$store.commit('quick_order/SET_CURRENT_ORDER', {
          ...this.currentOrder,
          line_items: lineItems
        })
        this.$store.dispatch('triggerEvent', {
          type: 'gy::saved-line-item-already-exists',
          message: NiceI18n.t('saved_orders.saved_line_item.already_exists')
        })

        return false
      }

      return true
    },
    increaseQuantity() {
      if (this.lineItemQuantity < this.item.max_quantity) {
        this.lineItemQuantity = this.lineItemQuantity + 1
      } else {
        this.$store.dispatch('triggerEvent', {
          type: 'gy::saved-line-item-max-quantity-reached',
          message: NiceI18n.t('flashes.line_item_max_quantity_reached')
        })
      }
    },
    decreaseQuantity() {
      if (this.lineItemQuantity > 1) {
        this.lineItemQuantity = this.lineItemQuantity - 1
      }
    },
    updateQuantity(quantity) {
      const newQt = parseInt(quantity)
      const lineItem = this.currentOrder.line_items.find(lineItem => lineItem.id == this.item.id)

      this.lineItemQuantity = newQt

      this.$emit('updateLineItemEl', {
        lineItem,
        quantity: newQt
      })
    },
    updateOrderId() {
      const lineItem = state.currentOrder.line_items.find(lineItem => lineItem.id == this.item.id)

      this.$emit('updateLineItemEl', {
        ...this.item,
        order_id: lineItem.order_id
      })
    },
    emitDelete() {
      this.$emit('deleteLineItemEl', this.item.id)
    }
  }
})
</script>
