/*
 * For usage import into component or vuex module with <api_name>API
 *
 * Example:
 * import OrdersAPI from '../../api/orders.js';
 * OrdersAPI.update("/b2b/el/api/v2/orders", 133, { title: "New Title" })
 *
 * adpated from:
 * https://pretagteam.com/question/how-to-structure-api-calls-in-vuejs
 */

import axios from 'axios'

axios.defaults.headers.common['x-csrf-token'] =
  !!document.querySelector('meta[name="csrf-token"]') &&
  document.querySelector('meta[name="csrf-token"]').content
axios.defaults.headers.common['Authorization'] = window.api_token
axios.defaults.headers.common['x-requested-with'] = 'XMLHttpRequest'

export default {
  index(apiPath, data = {}) {
    return axios.get(`${apiPath}/orders`, { params: data })
  },

  update(apiPath, id, data = {}) {
    return axios.patch(`${apiPath}/orders/${id}`, data, { validateStatus: false })
  },

  delete(apiPath, id) {
    return axios.delete(`${apiPath}/orders/${id}`)
  },

  create(apiPath, data = {}) {
    return axios.post(`${apiPath}/orders`, data)
  },

  duplicate(apiPath, id) {
    // set id to 'null' if duplication is called from a transient order
    return axios.post(`${apiPath}/orders/${id ? id : 'null'}/duplicate`)
  },

  checkCart(apiPath) {
    return axios.post(`${apiPath}/order/check_cart`)
  }
}
