<template>
  <ul :class="classes.quickOrderTable.headerRow">
    <li
      v-for="column in headerColumns"
      :key="column.key"
      :class="column.class"
    >
      {{ column.text }}
    </li>
  </ul>
</template>

<script>
import NiceI18n from '../../../lib/nice_i18n'

export default {
  name: "QuickOrderTableHeader",
  props: {
    classes: {
      type: Object,
      required: true
    },
    displayColumnKeys: {
      type: Array,
      default: () => ['code', 'title', 'quantity', 'total', 'actions']
    }
  },
  computed: {
    headerColumns() {
      const { columns } = this.classes.quickOrderTable
      const tableColumns = [
        {
          key: "product",
          class: columns.product.align,
          text: NiceI18n.t("quick_order.form.table.product")
        },
        {
          key: "code",
          class: columns.code.align,
          text: NiceI18n.t("quick_order.form.table.code")
        },
        {
          key: "title",
          class: columns.title.align,
          text: NiceI18n.t("quick_order.form.table.order_title")
        },
        {
          key: "quantity",
          class: columns.quantity.align,
          text: NiceI18n.t("quick_order.form.table.quantity")
        },
        {
          key: "pricePerProduct",
          class: columns.pricePerProduct.align,
          slotName: this.isPageViewList ? null : "pricePerProduct",
          text: NiceI18n.t("quick_order.form.table.price_per_product")
        },
        {
          key: "total",
          class: columns.total.align,
          text: NiceI18n.t("quick_order.form.table.total")
        },
        {
          key: "actions",
          class: columns.actions.align,
          text: ""
        }
      ]

      return tableColumns.filter(col => this.displayColumnKeys.includes(col.key))
    }
  }
}
</script>
