export default {
  quickOrderContainer: 'container w-10/12 mx-auto',
  quickOrderHeader: 'w-full flex flex-wrap justify-between items-center gap-3',
  quickOrderHeaderColumnsWrapper: 'flex-1 flex justify-center mb-3',
  quickOrderHeaderTitle: {
    container: 'flex flex-wrap md:flex-nowrap items-center gap-2',
    title: 'title font-bold'
  },
  quickOrderHeaderEditTitle: {
    container: 'flex items-center gap-2 border-solid border border-zinc-600 hover:border-zinc-400 px-5 py-2 cursor-pointer',
    input: 'border-0 p-0 w-32 sm:w-[unset]',
    editSpan: 'text-black hover:text-black-400 text-center min-w-[120px]'
  },
  quickOrderHeaderCode: 'flex-1 flex justify-center mb-3 whitespace-nowrap',
  addNewProduct: {
    container: 'w-full my-5',
    link: 'flex items-center justify-center w-full text-black px-0 py-3 border-2 border-dashed border-secondary',
    plusIcon: 'h-8 w-8 flex items-center justify-center mr-3 rounded-[100%] bg-black text-white'
  },
  quickOrderTable: {
    headerRow: 'hidden lg:grid grid-cols-12 gap-4 items-center font-medium text-xs lg:text-sm text-center bg-gray-900 text-white p-3 mb-4',
    bodyRow: 'lg:grid grid-cols-12 gap-4 items-center text-center p-3 lg:p-4 lg:mb-4',
    label: 'md:hidden text-sm text-left font-medium',
    columns: {
      product: {
        align: 'col-span-5 lg:col-span-4',
        body: {
          container: 'flex flex-wrap items-start',
          productSelect: 'basis-full lg:basis-[unset] lg:mr-3 w-96',
          titleWrapper: 'text-left pt-2'
        }
      },
      code: {
        align: 'col-span-2',
        body: {
          container: 'flex justify-between lg:justify-center text-center font-bold items-center gap-2 mt-3 lg:mt-0'
        }
      },
      title: {
        align: 'col-span-5',
        body: {
          container: 'flex justify-between items-center gap-2 mt-3 lg:mt-0'
        }
      },
      quantity: {
        align: 'col-span-2',
        body: {
          container: 'flex justify-between lg:!justify-center items-center gap-2 mt-3 font-xl lg:mt-0',
          select: 'block w-full text-base font-normal bg-white bg-clip-padding border rounded transition-[border-color] transition-shadow ease-in-out duration-1000 px-3 py-1.5 border-solid',
          inputButtons: {
            container: 'flex justify-around gap-2',
            decreaseBtn: 'w-8 h-8 leading-7 align-middle cursor-pointer p-0 rounded-[100px]',
            input: 'min-w-0 w-[50px] h-[35px] leading-[35px] text-[1.4rem] font-bold text-center align-middle p-0 rounded-[15px]',
            increaseBtn: 'w-8 h-8 leading-7 align-middle cursor-pointer p-0 rounded-[100px]'
          }
        }
      },
      pricePerProduct: {
        align: 'col-span-2 lg:col-span-3',
        body: {
          container: 'flex justify-between lg:justify-center items-center gap-2 mt-3 lg:mt-0'
        }
      },
      total: {
        align: 'col-span-2',
        body: {
          container: 'flex justify-between lg:justify-center items-center gap-2 mt-3 lg:mt-0 px-5'
        }
      },
      actions: {
        align: 'col-span-1 flex mt-4 justify-center lg:mt-0',
        body: {
          container: '',
          remove: 'rounded-lg p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
        }
      }
    }
  },
  quickOrderNoResults: 'text-center text-[33px]',
  quickOrderfinalCost: 'flex flex-wrap justify-center sm:justify-end bg-grey text-2xl font-bold px-12 py-5',
  quickOrderActions: {
    container: 'flex flex-col sm:flex-row flex-wrap justify-end mt-12',
    saveQuickOrder: 'mb-3 sm:mr-4 sm:mb-0 px-10 py-3 t btn-secondary',
    addQuickOrderToCart: 'btn-primary'
  }
}