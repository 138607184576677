<template>
  <div class="gdpr-cp-modal">
    <button
      class="gdpr-cp-close-modal"
      aria-label="X"
      @click="$emit('close')"
    >
      <i class="icon-nv-close" />
    </button>
    <div class="gdpr-cp-h1">
      {{ consentPreference.title }}
    </div>
    <div class="gdpr-cp-wrapper clearfix">
      <span v-html="consentPreference.description" />

      <span
        class="gdpr-cp-h2"
        v-html="consentPreference.prompt_message"
      />
      <div
        v-for="(value, key) in groupBy(siteConsentFields, 'group')"
        :key="key"
        class="gdpr-cp-group"
      >
        <div class="gdpr-cp-group-title">
          {{ key }}
        </div>
        <SiteConsentField
          v-for="field in value"
          :key="field.id"
          :field="field"
        />
      </div>
      <span v-html="consentPreference.footer" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    SiteConsentField: () => import('./site_consent_field.vue')
  },
  props: ["consentPreference", "siteConsentFields"],
  methods: {
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../assets/css/icomoon.scss';

  .v--modal-overlay {
    z-index: 16000003;
  }

  .v--modal {
    @media (max-width: 768px) {
      width: 96%!important;
      left: 2%!important;
    }
  }

  .gdpr-cp-option /deep/ .pretty {
    margin-right: 0;
    white-space: normal;

    .state .icon {
      background: none;
      text-indent: 0;
      top: 0 !important;
    }
  }

  .gdpr-cp-option /deep/ .pretty .state label {
    text-indent: 0;
    display: inline-block;
    padding-top:  0;
    padding-bottom: 0;
    line-height: 1 !important;

    &::after, &::before {
      top: 1px !important;
    }

    & > span {
      display: inline-block;
      padding-left: 1.8em;
      line-height: 1.3em;
    }
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  .clearfix {
    display: inline-block;
    box-sizing: border-box;
    clear: none;

    @media (max-width: 700px) {
      width: 100%;
    }
  }

  .gdpr-cp-close-modal {
    position: absolute;
    right: 20px;
    top: 18px;
    font-size: 18pt;
    border: none;
    color: #888;
    background: white;
    padding: 5px;

    &:hover {
      color: #000;
    }
  }

  .gdpr-cp-h1 {
    padding: 20px;
    font-size: 17pt;
    color: #222;
    font-weight: bold;
    text-align: center;
    border-bottom: 2px solid #f1f1f1;

    @media (max-width: 500px) {
      font-size: 15px;
    }
  }

  .gdpr-cp-h2 {
    display: block;
    font-size: 13pt;
    color: #222;
    font-weight: bold;
    padding: 4px 10px 8px 0;
  }

  .gdpr-cp-wrapper {
    font-size: 11pt;
    padding: 20px;
    overflow-y: auto;
    padding-bottom: 30px;
  }

  .gdpr-cp-option {
    padding: 5px 0 15px 0;
  }
  .gdpr-cp-group {
    padding-bottom: 2px;

    .gdpr-cp-group-title {
      font-weight: bold;
      color: #757575;
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 2px;
      font-size: 13px;
      margin-bottom: 4px;
    }
  }

  .icon-nv-close:before {
    content: "\fdd0";
    font-family: "icomoon";
  }
</style>