<script>
import Vue from 'vue'
import { EventBus } from '../../lib/event_bus.js'

export default Vue.component('EmailInput', {
  data() {
    return {
      tmt: null
    }
  },
  watch: {
    email: function(newValue, oldValue) {
      if (newValue != oldValue && newValue.length > 0) {
        clearTimeout(this.tmt)
        this.tmt = setTimeout(() => {
          EventBus.$emit(`${this.guid}-email-filled`, newValue)
        }, 400)
      }
    }
  },
  mounted() {
    EventBus.$emit(`${this.guid}-email-filled`, this.email)
  }
})
</script>
