export default {
  quick_order: {
    no_saved_orders: 'Δεν έχετε αποθηκευμένες παραγγελίες',
    saved_order: {
      add_to_cart_prompt: "Μόνο οι αποθηκευμένες αλλαγές θα προστεθούν στο καλάθι. Θέλετε να προχωρήσετε;",
      default_title: "Χωρίς όνομα",
      no_line_items_on_save: "Μη επιτρεπτή ενέργεια. Η παραγγελία έχει μηδέν προϊόντα.",
      prompt_to_delete: "Είστε σίγουρος ότι θέλετε να διαγράψετε την παραγγελία αυτή;"
    },
    form: {
      add_new_product: 'προσθήκη νέου προϊόντος',
      add_to_cart: 'Προσθήκη στο καλάθι',
      code: 'Κωδικός παραγγελίας',
      edit: 'Επεξεργασία',
      save: 'Αποθήκευση παραγγελίας',
      table: {
        barcode: 'Barcode',
        final_cost: 'Τελικό ποσό',
        code: 'ΚΩΔΙΚΟΣ ΠΑΡΑΓΓΕΛΙΑΣ',
        order_title: 'ΤΙΤΛΟΣ ΠΑΡΑΓΓΕΛΙΑΣ',
        price_per_product: 'ΤΙΜΗ ANA TEMAXIO',
        product: 'ΠΡΟΙΟΝ',
        quantity: 'ΠΟΣΟΤΗΤΑ',
        total: 'ΣΥΝΟΛΟ'
      },
      title: 'Ο Τίτλος της παραγγελίας μου'
    }
  }
}