var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{class:_vm.classes.quickOrderTable.bodyRow},[_c('li',{class:[
      _vm.classes.quickOrderTable.columns.code.align,
      _vm.classes.quickOrderTable.columns.code.body.container
    ]},[_c('span',{class:_vm.classes.quickOrderTable.label},[_vm._v("\n      "+_vm._s(_vm.NiceI18n.t("quick_order.form.table.code"))+"\n    ")]),_vm._v(" "),_c('span',{},[_vm._v(_vm._s(_vm.order.code))])]),_vm._v(" "),_c('li',{class:[
      _vm.classes.quickOrderTable.columns.title.align,
      _vm.classes.quickOrderTable.columns.title.body.container
    ]},[_c('span',{class:_vm.classes.quickOrderTable.label},[_vm._v("\n      "+_vm._s(_vm.NiceI18n.t("quick_order.form.table.order_title"))+"\n    ")]),_vm._v(" "),_c('span',{},[_vm._v(_vm._s(_vm.order.title))])]),_vm._v(" "),_c('li',{class:[
      _vm.classes.quickOrderTable.columns.quantity.align ]},[_c('div',{class:[_vm.classes.quickOrderTable.columns.quantity.body.container]},[_c('span',{class:_vm.classes.quickOrderTable.label},[_vm._v("\n        "+_vm._s(_vm.NiceI18n.t("quick_order.form.table.quantity"))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"mt-2"},[_vm._v("\n        "+_vm._s(_vm.order.total_quantity)+"\n      ")])])]),_vm._v(" "),_c('li',{class:[
      _vm.classes.quickOrderTable.columns.total.align,
      _vm.classes.quickOrderTable.columns.total.body.container
    ]},[_c('strong',{staticClass:"md:hidden text-left"},[_vm._v("\n      "+_vm._s(_vm.NiceI18n.t("quick_order.form.table.total"))+"\n    ")]),_vm._v(" "),_c('strong',{staticClass:"text-2xl"},[_vm._v("\n      "+_vm._s(_vm.order.final_cost)+"\n    ")])]),_vm._v(" "),_c('li',{class:[
      _vm.classes.quickOrderTable.columns.actions.align,
      _vm.classes.quickOrderTable.columns.actions.body.container
    ]},[_c('a',{class:_vm.classes.quickOrderTable.columns.actions.body.remove,attrs:{"href":_vm.order.saved_order_edit_url}},[_c('span',{staticClass:"hidden"},[_vm._v("Edit order")]),_vm._v(" "),_c('EditSvgIcon')],1),_vm._v(" "),_c('button',{class:_vm.classes.quickOrderTable.columns.actions.body.remove,attrs:{"data-test-id":"addOrderToCart"},on:{"click":_vm.addOrderToCart}},[_c('span',{staticClass:"hidden"},[_vm._v("Add to cart")]),_vm._v(" "),_c('CartSvgIcon')],1),_vm._v(" "),_c('button',{class:_vm.classes.quickOrderTable.columns.actions.body.remove,attrs:{"data-test-id":"destroyOrder"},on:{"click":_vm.destroyOrder}},[_c('span',{staticClass:"hidden"},[_vm._v("Delete item")]),_vm._v(" "),_c('CloseSvgIcon')],1)])])}
var staticRenderFns = []
export { render, staticRenderFns }