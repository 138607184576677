import Vue from 'vue'

/**
 * Round a decimal number (percentage) to a given precision and append a percentage sign.
 *
 * @param {string} percentString - The percentage value (as a string) that you want to round.
 *
 * @param {number} [precision=0] - The number of decimal places to round the percentage value to.
 *                                 If omitted, the value is rounded to 0 decimal places by default.
 *
 * @returns {string} The rounded percentage value as a string, with the specified precision and a '%' sign appended.
 *
 * @example
 * <!-- Default usage (rounds to 0 decimal places) -->
 * {{ item.discount_percentage | round-percentage }}
 * <!-- Example with input "12.3456" -->
 * <!-- Output: "12%" -->
 *
 * @example
 * <!-- Custom precision usage (rounds to specified decimal places) -->
 * {{ item.discount_percentage | round-percentage(2) }}
 * <!-- Example with input "12.3456" -->
 * <!-- Output: "12.35%" -->
 */

Vue.filter('round-percentage', (percentString, precision = 0) => {
  if (!percentString) return ''

  let number = parseFloat(percentString.replace(',', '.'))

  if (isNaN(precision) || precision < 0) precision = 0

  return number.toFixed(precision) + '%'
})
