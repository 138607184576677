import axios from 'axios'

export default {
  methods: {
    /*
     * Loads the saved orders from the API. Its called once when app is mounted.
     */
    async loadSavedOrders () {
      const reqPath = `${this.apiPath}/saved_orders`
      const res = await axios.get(reqPath)
      
      this.savedOrders = res.data.saved_orders
      this.$store.dispatch('triggerEvent', 'gy::saved-orders-loaded')
    },

    /*
     * Adds, removes or manipulates custom attributes
     * @param {savedOrder} the saved order to manipulate
     */
    handleCustomAttributes (savedOrder) {
      savedOrder.check_all = false

      savedOrder.line_items.forEach(line_item => {
        line_item.is_checked = false
      })
    }
  }
}
