<template>
  <div :class="classes.quickOrderContainer">
    <div :class="classes.quickOrderHeader">
      <div :class="classes.quickOrderHeaderColumnsWrapper">
        <div :class="classes.quickOrderHeaderTitle.container">
          <div :class="classes.quickOrderHeaderTitle.title">
            {{ NiceI18n.t("quick_order.form.title") }}:
          </div>
          <div
            :class="classes.quickOrderHeaderEditTitle.container"
            @click="() => !isEditingTitle && toggleTitleEdit()"
          >
            <EditSvgIcon />
            <input
              v-show="isEditingTitle"
              ref="order_title"
              v-model="currentOrderTitle"
              type="text"
              :class="classes.quickOrderHeaderEditTitle.input"
              @blur="saveTitleEdit"
            >
            <span
              v-show="!isEditingTitle"
              :class="classes.quickOrderHeaderEditTitle.editSpan"
            >
              {{ currentOrder.title || NiceI18n.t("quick_order.form.edit") }}
            </span>
          </div>
        </div>
      </div>
      <div :class="classes.quickOrderHeaderCode">
        {{ NiceI18n.t("quick_order.form.code") }}:
        {{ currentOrder.code }}
      </div>
    </div>

    <div :class="classes.addNewProduct.container">
      <a
        href="#"
        :class="classes.addNewProduct.link"
        data-test-id="addLineItemButton"
        @click.prevent="addLineItemEl"
      >
        <div :class="classes.addNewProduct.plusIcon">
          <PlusSvgIcon
            stroke-width="3"
            width="32"
            height="32"
          />
        </div>
        <span class="uppercase">{{ NiceI18n.t("quick_order.form.add_new_product") }}</span>
      </a>
    </div>

    <div>
      <slot name="tableHeader" />

      <LineItemProduct
        v-for="(lineItem, index) in currentOrder.line_items"
        :key="lineItem.id"
        :item="lineItem"
        :line-item-index="index"
        @updateLineItemEl="updateLineItemEl"
        @deleteLineItemEl="(id) => deleteLineItemEl(id)"
        @handleLineItemCheck="handleLineItemCheck"
      />
    </div>

    <div
      v-show="displayTotalDiscountedPrice"
      :class="classes.quickOrderfinalCost"
    >
      <div>
        {{ NiceI18n.t("quick_order.form.table.final_cost") }}
      </div>
      <div class="ml-4">
        {{ displayTotalDiscountedPrice }}
      </div>
    </div>

    <div :class="classes.quickOrderActions.container">
      <a
        :class="[
          classes.quickOrderActions.saveQuickOrder,
          { 'pointer-events-none opacity-50': emptyOrder }
        ]"
        href="#"
        @click.prevent="saveQuickOrder"
      >
        {{ NiceI18n.t("quick_order.form.save") }}
      </a>
      <a
        href="#"
        :class="[
          classes.quickOrderActions.addQuickOrderToCart,
          { 'pointer-events-none opacity-50': emptyOrder }
        ]"
        @click.prevent="addQuickOrderToCart"
      >
        {{ NiceI18n.t("quick_order.form.add_to_cart") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import LineItemProduct from './LineItem/LineItemProduct.vue'
import EditSvgIcon from '../layout/icon/EditSvgIcon.vue'
import PlusSvgIcon from '../layout/icon/PlusSvgIcon.vue'

import NiceI18n from '../../lib/nice_i18n'

export default ({
  name: 'QuickOrder',
  components: {
    LineItemProduct,
    EditSvgIcon,
    PlusSvgIcon
  },
  inject: ['classes'],
  props: {
    noDefaultTitle: {
      type: Boolean,
      default: false
    },
    openNewLineItemOnStartup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      NiceI18n,
      isEditingTitle: false,

      currentOrderTitle: this.currentOrder?.title || '',

      errors: null,
      useDefaultTitle: true
    }
  },
  computed: {
    ...mapState('quick_order', [
      'currentOrder',
      'formattedTotalPrice',
      'formattedTotalDiscountedPrice',
      'formattedTotalQuantity'
    ]),
    ...mapGetters(['apiPath']),
    isEditing() {
      return !!this.currentOrder.id
    },
    emptyOrder() {
      const { line_items } = this.currentOrder
      return !line_items || !line_items.some(lineItem => lineItem?.product_id)
    },
    hasDefaultTitle() {
      return this.currentOrder?.title == NiceI18n.t('quick_order.saved_order.default_title')
    },
    displayTotalPrice() {
      return this.formattedTotalPrice || this.currentOrder.total_cost
    },
    displayTotalDiscountedPrice() {
      return this.formattedTotalDiscountedPrice || this.currentOrder.discounted_cost
    },
    displayTotalQuantity() {
      return this.formattedTotalQuantity || this.currentOrder.total_quantity
    },
    hasCheckedLineItems() {
      const { line_items } = this.currentOrder
      return !!(line_items && line_items.some(lineItem => lineItem.is_checked))
    },
    allLineItemsChecked() {
      const { line_items } = this.currentOrder
      return !!(line_items && line_items.every(lineItem => lineItem.is_checked))
    },
    errorTitle() {
      return this.errors?.title?.[0] ? this.errors.title[0] : ''
    }
  },
  watch: {
    currentOrderTitle(newTitle) {
      this.$store.commit('quick_order/SET_CURRENT_ORDER', {
        ...this.currentOrder,
        title: newTitle
      })
    }
  },
  mounted() {
    this.displayScreenSpecs()
  },
  methods: {
    async displayScreenSpecs() {
      // total order calculation
      if (this.currentOrder?.id) {
        await this.$store.dispatch('quick_order/calculateDisplayOrder', { lineItems: this.currentOrder.line_items })
      }

      // title
      if (this.noDefaultTitle && this.hasDefaultTitle) {
        this.useDefaultTitle = false
        this.$store.commit('quick_order/SET_CURRENT_ORDER', {
          ...this.currentOrder,
          title: ''
        })
      }

      if (!this.currentOrder.id && this.currentOrder.line_items && this.openNewLineItemOnStartup) {
        this.addLineItemEl()
      }
    },
    async createOrUpdate() {
      const actionPath = this.isEditing ? 'updateOrder' : 'createOrder'
      const { errors } = await this.$store.dispatch(`quick_order/${actionPath}`, {
        order: this.currentOrder
      })

      if (errors) {
        this.errors = errors
      }
    },
    saveQuickOrder() {
      if (this.emptyOrder) {
        this.$store.dispatch('triggerEvent', {
          type: 'gy::saved-order-no-line-items-on-save',
          message: NiceI18n.t('quick_order.saved_order.no_line_items_on_save')
        })
      } else {
        this.createOrUpdate()
      }
    },
    addQuickOrderToCart() {
      this.$store.dispatch('quick_order/addQuickOrderToCartByProducts', this.currentOrder.line_items)
    },
    addLineItemEl() {
      const hasNoLineItems = this.currentOrder.line_items.length === 0
      const hasNoLineItemsWithNullProductId = this.currentOrder.line_items.every(lineItem => lineItem.product_id !== null)

      if (hasNoLineItems || hasNoLineItemsWithNullProductId) {
        const newLineItem = {
          id: Date.now(),
          order_id: this.currentOrder.id,
          product_id: null,
          sku: {},
          options: {},
          quantity: 1,
          price: null,
          discounted_price: null,
          total_discounted_price: null,
          vat_rate: null,
          custom_attributes: {}
        }

        this.$store.commit('quick_order/SET_CURRENT_ORDER', {
          ...this.currentOrder,
          line_items: [{ ...newLineItem }, ...this.currentOrder.line_items]
        })
      }
    },
    updateLineItemEl(lineItem) {
      this.$store.commit('quick_order/UPDATE_CURRENT_ORDER_LINE_ITEM', lineItem)
      this.$store.dispatch('quick_order/calculateDisplayOrder', { lineItems: this.currentOrder.line_items })
    },
    deleteLineItemEl(lineItemId) {
      this.$store.commit('quick_order/REMOVE_CURRENT_ORDER_LINE_ITEM', lineItemId)
      this.$store.dispatch('quick_order/calculateDisplayOrder', { lineItems: this.currentOrder.line_items })
    },
    toggleTitleEdit() {
      const input = this.$refs.order_title
      this.isEditingTitle = !this.isEditingTitle

      // Focus input field
      if (this.isEditingTitle) {
        if (this.hasDefaultTitle) {
          this.$store.commit('quick_order/SET_CURRENT_ORDER', {
            ...this.currentOrder,
            title: null
          })
        }

        this.$nextTick(() => {
          input.focus()
        })
      }
    },
    saveTitleEdit() {
      // On blur event (currently only toggles inline edit back to normal)
      if (!this.currentOrder.title && this.useDefaultTitle) {
        this.$store.commit('quick_order/SET_CURRENT_ORDER', {
          ...this.currentOrder,
          title: NiceI18n.t('quick_order.saved_order.default_title')
        })
      }

      this.toggleTitleEdit()
    },
    handleLineItemCheck() {
      this.$store.commit('quick_order/SET_CURRENT_ORDER', {
        ...this.currentOrder,
        check_all: this.allLineItemsChecked
      })
    }
  }
})
</script>
