<template>
  <div>
    <a
      v-if="!display && keepPopupVisible"
      href="#"
      class="gdpr-cookie-modal cornered"
      :alt="updatePreferencesAltText"
      @click.prevent="setDisplayPopup(true)"
    >
      <span class="icon-nv-cookie" />
      <span class="gdpr-cookie-modal__text"> {{ cookieConsent.title }} </span>
    </a>

    <div
      v-if="display"
      class="gdpr-cookie-modal"
    >
      <ModalsContainer @cookieAgree="agree" />

      <div class="gdpr-cm-container">
        <div class="gdpr-cm-maintitle">
          {{ cookieConsent.title }}
        </div>
        <span v-html="cookieConsent.description" />
        <div class="gdpr-cm-buttons">
          <div class="cells">
            <div
              class="cell"
              style="width: 70%;"
            >
              <div class="gdpr-cm-cookie-selection">
                <template v-if="standardModal">
                  <div
                    v-for="category in categories"
                    :key="category.id"
                    class="gdpr-cm-cookie-category-selection-wrapper"
                  >
                    <div class="checkbox-container pretty">
                      <div class="state">
                        <input
                          :id="category.id"
                          v-model="category.agree"
                          type="checkbox"
                          name="check"
                          :disabled="category.disabled"
                        >
                        <label
                          :for="category.id"
                          v-html="category.title"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <div class="gdpr-cm-cookie-category-selection-wrapper gdpr-cm-show-details-wrapper">
                  <a
                    href="#"
                    class="gdpr-cm-show-details"
                    @click.prevent="openCookieDetails()"
                  >
                    <span v-if="standardModal">
                      {{ translations.more_information }}
                    </span>
                    <span v-if="optimizedModal">
                      {{ translations.cookie_settings }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="cell">
              <a
                href="#"
                class="gdpr-cm-accept"
                @click.prevent="agree()"
              >
                <i class="icon-nv-done_all" />
                {{ cookieConsent.accept_button }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue                  from 'vue'
import VModal               from 'vue-js-modal'
import * as Cookies         from "js-cookie"
import CookieSettingsModal  from './cookie_settings_modal.vue'
import CookieCategory       from './cookie_category.vue'
import MoadCookie           from '../../classes/moad/cookie'
import NiceI18n             from '../../lib/nice_i18n'
import Utils                from '../../lib/utils'

Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true  })

export default Vue.component('CookieModal', {
  data() {
    return {
      tmt: null,
      consent: null,
      cookies: [],
      selectedCategory: null,
      displayPopup: false
    }
  },
  computed: {
    display() {
      return (typeof(this.consent) === "undefined" || this.displayPopup)
    },
    keepPopupVisible() {
      return (this.cookieConsent || {}).keep_popup_visible || false
    },
    cookiesVersion() {
      return (this.cookieConsent || {}).version || 1
    },
    versionUpdated() {
      return (this.cookiesVersion > (this.consent || {}).version)
    },
    categories() {
      return (this.cookieConsent || {}).categories
    },
    translations() {
      return (this.cookieConsent || {}).translations
    },
    standardModal() {
      return (this.cookieConsent || {}).modal_type == "standard"
    },
    optimizedModal() {
      return (this.cookieConsent || {}).modal_type == "optimized"
    },
    updatePreferencesAltText() {
      return NiceI18n.t('gdpr.cookies.popup.update_preferences')
    }
  },
  mounted() {
    this.getConsent()

    // Preselect first cookie category
    if (!this.selectedCategory) {
      this.selectCategory = this.categories[0]
    }
  },
  methods: {
    openCookieDetails() {
      this.$modal.show(CookieSettingsModal, {
        categories: this.categories,
        defaultCategory: this.selectedCategory || this.categories[0],
        translations: this.translations,
        cookieConsent: this.cookieConsent
      }, {
        height: "auto",
        width: 800,
        adaptive: true,
        scrollable: false,
        clickToClose: false,
        root: this
      })
    },
    categorySelected(category) {
      return this.selectCategory == category
    },
    setDisplayPopup(value) {
      this.displayPopup = value
      window.gyAnalyticsParams = []
    },
    agree() {
      var payload = { version: this.cookiesVersion }

      for (let category of this.categories) {
        payload[category.code] = category.agree
      }

      this.setDomainAndToken()
      var $this = this

      this.$http.post("cookie_approvals", {
        domain: $this.domain,
        uuid: ($this.consent || {}).uuid,
        payload: payload,
        locale: window.locale || $this.locale
      }, {
        headers: {
          "X-Authorization-Token": $this.token
        }
      }).then(response => {
        payload["uuid"] = response.body.uuid

        let preferencesRemainedTheSame
        let oldCookie = Cookies.get("GDPRCookieConsent")

        if (oldCookie) {
          oldCookie = oldCookie ? JSON.parse(oldCookie) : {}
          preferencesRemainedTheSame = Utils.deepEqual(payload, oldCookie)
        } else {
          // in order to not reload/refresh the page
          preferencesRemainedTheSame = true
        }

        Cookies.set("GDPRCookieConsent", payload, { expires: 365 })
        $this.consent = payload
        this.displayPopup = false

        if (!preferencesRemainedTheSame)
          window.location.reload()

        document.dispatchEvent(new Event('gy::consents-updated'))

        $this.reInitializeGyAnalytics()
      })
    },
    getConsent() {
      this.consent = Cookies.getJSON("GDPRCookieConsent")

      if (this.versionUpdated) {
        Cookies.remove("GDPRCookieConsent")
        this.consent = undefined
      }
    },
    setDomainAndToken() {
      if (window.gy_moad != undefined) {
        this.domain = window.gy_moad.domain
        this.token  = window.gy_moad.token

        if (window.gy_moad.locale != undefined) {
          this.locale = window.gy_moad.locale
        } else {
          this.locale = "el"
        }
      } else {
        this.domain = window.location.hostname
        this.token  = ''
        this.locale = "el"
      }
    },
    reInitializeGyAnalytics() {
      // Run Cookie initialization logic again
      let cookie = new MoadCookie({ reinitialized: true })

      // Refrech GYCookie with the newly created
      GYCookie = cookie

      // Initialize GY Analytics
      if (window["initializeGYAnalytics"] != undefined) {
        window.initializeGYAnalytics(window.gyAnalyticsParams)
      }
    }
  }
})
</script>

<style lang="scss">
  @import '../../assets/css/icomoon.scss';

  .pretty input[disabled] ~ * {
    opacity: 0.6;
  }

  .checkbox {
    &-container {
      &.pretty {
        .state {
          display: flex;
          position: relative;
          cursor: pointer;

          label {
            cursor: pointer;
            padding: 3px 10px 1px !important;

            &::before,
            &::after { display: none; }

            p { margin: 0; }
          }

          input {
            height: 17px;
            width: 17px;
            border-radius: 3px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 1px solid rgb(229, 226, 226);
            z-index: 1;
            cursor: pointer;
            opacity: unset;
            position: unset !important;
            margin: unset !important;
            padding: unset !important;
            box-sizing: border-box;
            flex: 0 0 17px;

            &:checked {
              &::before {
                content: '\2713';
                animation: bounce .4s ease;
                color: white;
                background-color: #5cb85c;
                position: absolute;
                left: 0;
                top: 0;
                width: 17px;
                height: 17px;
                line-height: 17px;
                padding-left: 3px;
                display: block;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }
  }

  .gdpr-cookie-modal {
    width: 100%;
    max-width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #333;
    background-color: rgba(0, 0, 0, 0.92);
    color: #fff;
    padding: 20px;
    padding-bottom: 10px;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    z-index: 16000002;

    &__text {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }

    &.cornered {
      right: 20px;
      width: 50px;
      padding: 9px 5px;
      background-color: rgba(0, 0, 0, 0.80);
      text-align: center;

      .icon-nv-cookie { font-size: 24px; }

      &:hover {
        background-color: black;
      }
    }

    .pretty .state label {
      display: inline-block;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 1;
    }

    * {
      box-sizing: border-box !important;
    }

    &.gdpr-cm-white {
      background-color: #fff !important;
      box-shadow: 0 0 30px -10px #000 !important;

      .gdpr-cm-container {
        color: #000 !important;

        p { color: #000; }
      }

      .pretty .state label { color: #000 !important; }

      .gdpr-cm-buttons a.gdpr-cm-show-details {
        border-color: #000 !important;
        color: #000 !important;

        &:hover,
        &:focus {
          background: #fff !important;
        }
      }
    }

    @media (max-width: "600px") {
      padding: 10px;
    }

    .gdpr-cm-container {
      width: 80%;
      max-width: 960px;
      margin: 10px auto 4px auto;
      line-height: 1.4;

      p {
        color: #fff;
      }

      .cells {
        box-sizing: border-box;
        display: table;
        width: 100%;
        table-layout: fixed;

        .cell {
          display: table-cell;
          vertical-align: middle;

          &:last-child {
            text-align: right;
          }
        }
      }

      .pretty .state label { color: #fff; }

      @media (max-width: "600px") {
        width: 100%;

        .gdpr-cm-maintitle { font-size: 13px !important; }
        p { font-size: 11px; }

        .cells {
          display: block;
          width: 100%;
          float: left;

          .cell {
            float: left;
            width: 100% !important;
            display: block;
          }
        }

        .gdpr-cm-buttons a.gdpr-cm-show-details {
          width: 100%;
          display: block;
          margin-bottom: 6px;
          box-sizing: border-box;
        }

        .gdpr-cm-buttons a.gdpr-cm-accept {
          float: left;
          width: 100%;
          display: block;
          text-align: center;
          font-size: 13px;
        }

        .gdpr-cm-cookie-selection .gdpr-cm-cookie-category-selection-wrapper {
          width: 100%;
          display: block;
        }
      }

      .gdpr-cm-cookie-selection {
        font-size: 13px;
        display: flex;
        align-items: center;

        @media (max-width: "900px") {
          display: block;
        }

        .gdpr-cm-cookie-category-selection-wrapper {
          display: inline-block;
          font-size: 13px;
        }
      }

      .gdpr-cm-maintitle {
        font-size: 19px;
      }
    }
  }

  .gdpr-cm-buttons {
    padding-top: 10px;

    a {
      color: white;
      text-decoration: none;
    }

    a.gdpr-cm-accept {
      position: relative;
      border-radius: 5px;
      font-weight: 600;
      padding: 10px 20px;
      background-color: #eb0000;
      box-sizing: border-box;
      padding-left: 45px;
      line-height: 24px;
      display: flex;
      align-items: center;
      width: max-content;

      i {
        font-size: 24px;
        position: absolute;
        left: 14px;
      }
    }

    a.gdpr-cm-reject {
      margin-left: 10px;
      font-weight: normal;
      color: white;
      padding: 10px 20px;

      &:hover {
        background-color: #333;
      }
    }

    a.gdpr-cm-show-details {
      display: inline-block;
      position: relative;
      border-radius: 5px;
      font-size: 11px;
      padding: 4px 8px 4px 8px;
      border: 1px solid;
      border-color: white;
      border-color: rgba(255, 255, 255, 0.2);
      z-index: 2;
      margin-top: 4px;
      padding-top: 12px;
      padding-bottom: 12px;

      i {
        margin-left: 5px;
      }

      &:hover {
        background-color: #111;
        background: linear-gradient(#222, #111);
        text-decoration: underline;
      }
    }
  }

  .icon-nv-cookie:before {
    content: "\fdd1";
    font-family: "icomoon";
  }

  .icon-nv-done_all:before {
    content: "\fdd2";
    font-family: "icomoon";
  }
</style>
