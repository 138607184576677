<template>
  <ul :class="classes.quickOrderTable.bodyRow">
    <!-- Order code -->
    <li
      :class="[
        classes.quickOrderTable.columns.code.align,
        classes.quickOrderTable.columns.code.body.container
      ]"
    >
      <span :class="classes.quickOrderTable.label">
        {{ NiceI18n.t("quick_order.form.table.code") }}
      </span>
      <span class="">{{ order.code }}</span>
    </li>

    <!-- Order title -->
    <li
      :class="[
        classes.quickOrderTable.columns.title.align,
        classes.quickOrderTable.columns.title.body.container
      ]"
    >
      <span :class="classes.quickOrderTable.label">
        {{ NiceI18n.t("quick_order.form.table.order_title") }}
      </span>
      <span class="">{{ order.title }}</span>
    </li>

    <!-- Quantity -->
    <li
      :class="[
        classes.quickOrderTable.columns.quantity.align,
      ]"
    >
      <div
        :class="[classes.quickOrderTable.columns.quantity.body.container]"
      >
        <span :class="classes.quickOrderTable.label">
          {{ NiceI18n.t("quick_order.form.table.quantity") }}
        </span>
        <div class="mt-2">
          {{ order.total_quantity }}
        </div>
      </div>
    </li>

    <!-- Total -->
    <li
      :class="[
        classes.quickOrderTable.columns.total.align,
        classes.quickOrderTable.columns.total.body.container
      ]"
    >
      <strong class="md:hidden text-left">
        {{ NiceI18n.t("quick_order.form.table.total") }}
      </strong>
      <strong class="text-2xl">
        {{ order.final_cost }}
      </strong>
    </li>

    <!-- Actions -->
    <li
      :class="[
        classes.quickOrderTable.columns.actions.align,
        classes.quickOrderTable.columns.actions.body.container
      ]"
    >
      <a
        :href="order.saved_order_edit_url"
        :class="classes.quickOrderTable.columns.actions.body.remove"
      >
        <span class="hidden">Edit order</span>
        <EditSvgIcon />
      </a>
      <button
        :class="classes.quickOrderTable.columns.actions.body.remove"
        data-test-id="addOrderToCart"
        @click="addOrderToCart"
      >
        <span class="hidden">Add to cart</span>
        <CartSvgIcon />
      </button>
      <button
        :class="classes.quickOrderTable.columns.actions.body.remove"
        data-test-id="destroyOrder"
        @click="destroyOrder"
      >
        <span class="hidden">Delete item</span>
        <CloseSvgIcon />
      </button>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

import EditSvgIcon from '../../layout/icon/EditSvgIcon.vue'
import CartSvgIcon from '../../layout/icon/CartSvgIcon.vue'
import CloseSvgIcon from '../../layout/icon/CloseSvgIcon.vue'

import NiceI18n from '../../../lib/nice_i18n'

export default ({
  name: 'LineItemQuickOrder',
  components: {
    EditSvgIcon,
    CartSvgIcon,
    CloseSvgIcon
  },
  inject: ['classes'],
  props: {
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      NiceI18n
    }
  },
  computed: {
    ...mapGetters([
      'apiPath'
    ])
  },
  methods: {
    addOrderToCart() {
      this.$store.dispatch('quick_order/addOrderToCartById', this.order.id)
    },
    destroyOrder() {
      this.$store.dispatch('quick_order/destroyOrder', this.order.id)
    }
  }
})
</script>
