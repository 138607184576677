import Utils from '../lib/utils'

function loadKlarnaSDK() {
  if (klarnaLoaded())
    return

  window.gy_klarna.loaded = true

  const script = document.createElement('script')
  script.src = 'https://js.klarna.com/web-sdk/v1/klarna.js'
  script.async = true
  script.setAttribute('data-environment', window.gy_klarna.environment)
  script.setAttribute('data-client-id', window.gy_klarna.client_id)
  document.body.appendChild(script)

  Utils.logNotice(`Script loaded`, 'Klarna')
}

function klarnaLoaded() {
  return !!window.gy_klarna?.loaded
}

document.addEventListener('DOMContentLoaded', () => {
  if (window.gy_klarna)
    loadKlarnaSDK()
})
