<template>
  <div class="gdpr-cm-cookie-settings-modal">
    <button
      class="gdpr-cm-close-modal"
      aria-label="X"
      @click="$emit('close')"
    >
      <i class="icon-nv-close" />
    </button>
    <div class="gdpr-cm-modal-title">
      {{ translations.modal_title }}
    </div>
    <div class="clearfix">
      <div class="gdpr-cm-categories clearfix">
        <CookieCategory
          v-for="category in categories"
          :key="category.id"
          :selected-category="selectedCategory"
          :category="category"
        />
      </div>
      <div class="gdpr-cm-cookie-list clearfix">
        <div class="gdpr-cm-modal-subtitle">
          {{ selectedCategory.title }}

          <div
            class="switch-container"
            :class="{ disabled: selectedCategory.disabled }"
          >
            <label class="switch">
              <input
                v-model="selectedCategory.agree"
                type="checkbox"
                name="check"
                :disabled="selectedCategory.disabled"
              >
              <span class="slider round" />
              <p class="switch__accessibility-text">
                <span v-if="selectedCategory.agree">
                  {{ translations.active }}
                </span>
                <span v-if="!selectedCategory.agree">
                  {{ translations.inactive }}
                </span>
              </p>
            </label>
            <label>
              <span
                v-if="selectedCategory.agree"
                class="gdpr-cm-enabled"
              >
                {{ translations.active }}
              </span>
              <span
                v-if="!selectedCategory.agree"
                class="gdpr-cm-disabled"
              >
                {{ translations.inactive }}
              </span>
            </label>
          </div>
        </div>

        <span v-html="selectedCategory.description" />

        <div class="gdpr-cm-modal-subtitle">
          {{ translations.cookie_list }}
        </div>
        <table class="gdpr-cm-cookie-table">
          <thead>
            <tr>
              <th>{{ translations.cookie_name }}</th>
              <th>{{ translations.cookie_provider }}</th>
              <th>{{ translations.cookie_expires }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="cookie in selectedCategory.cookies"
              :key="cookie.id"
            >
              <td class="gdpr-cm-cookie-name">
                {{ cookie.name }}
              </td>
              <td class="gdpr-cm-cookie-provider">
                {{ cookie.provider }}
              </td>
              <td class="gdpr-cm-cookie-expiry">
                {{ cookie.expiry }}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="gdpr-cm-buttons">
          <a
            href="#"
            class="gdpr-cm-accept"
            @click.prevent="agree()"
          >
            <i class="icon-nv-done_all" />
            {{ cookieConsent.accept_button }}
          </a>
        </div>
        <p class="gdpr-cm-last-updated">
          {{ translations.last_updated_on }} {{ cookieConsent.updated_at }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { EventBus } from '../../lib/event_bus.js'

export default Vue.component('CookieSettingsModal', {
  components: {
    CookieCategory: () => import('./cookie_category.vue')
  },
  props: {
    categories: {
      default: () => [],
      type: Array
    },
    defaultCategory: {
      type: Object,
      default: undefined
    },
    translations: {
      default: () => {
        return {}
      },
      type: Object
    },
    cookieConsent: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      selectedCategory: {}
    }
  },
  mounted() {
    this.selectedCategory = this.defaultCategory
    EventBus.$on('cookieCategorySelected', this.selectCategory)
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category
    },
    agree() {
      this.$emit('cookieAgree')
      this.$emit('close')
    }
  }
})
</script>

<style lang="scss">
  @import '../../assets/css/icomoon.scss';

  .v--modal-overlay {
    z-index: 16000003;
  }

  .v--modal {
    @media (max-width: 768px) {
      width: 96% !important;
      left: 2% !important;
    }
  }

  .switch-container {
    display: flex;
    justify-content: space-between;

    &.disabled { opacity: 0.6; }

    .switch {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 19px;

      &__accessibility-text {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;

        &.round {
          border-radius: 34px;

          &::before { border-radius: 50%; }
        }

        &::before {
          position: absolute;
          content: "";
          height: 13px;
          width: 13px;
          left: 3px;
          bottom: 3px;
          background-color: white;
          transition: .4s;
        }
      }

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked {
          + .slider {
            background-color: #6cc04a;

            &::before { transform: translateX(16px); }
          }
        }
      }
    }
  }

  .gdpr-cm-cookie-settings-modal {
    color: black;

    .gdpr-cm-last-updated {
      font-size: 10px;
      color: #555;
      text-align: right;
      padding: 10px 0;
    }

    .gdpr-cm-buttons {
      margin-top: 20px;
      padding: 5px 0;
      text-align: right;
      display: flex;
      justify-content: end;
    }

    .clearfix::after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
    }

    .clearfix {
      box-sizing: border-box;
      clear: none;

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }

  .gdpr-cm-close-modal {
    position: absolute;
    right: 20px;
    top: 18px;
    font-size: 18pt;
    border: none;
    color: #888;
    background: transparent;
    padding: 5px;

    &:hover {
      background: transparent;
      color: #000;
    }
  }

  .gdpr-cm-modal-title {
    padding: 20px;
    font-size: 17pt;
    color: #222;
    font-weight: bold;
    text-align: center;
    border-bottom: 2px solid #f1f1f1;

    @media (max-width: 500px) {
      font-size: 15px;
    }
  }

  .gdpr-cm-modal-subtitle {
    font-size: 12pt;
    color: #222;
    font-weight: bold;
    padding: 4px 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 700px) {
      font-size: 13px;
    }
  }

  .gdpr-cm-categories {
    float: left;
    white-space: nowrap;
    font-size: 11pt;
    width: 25%;
    min-width: 120px;
    background-color: #f1f1f1;
    min-height: 600px;
    overflow-y: hidden;


    a {
      display: flex;
      align-items: center;
      padding: 12px 14px;
      line-height: 1.3;
      color: #616161;
      font-weight: bold;
      background-color: #ddd;
      border-bottom: 1px solid #d1d1d1;
      position: relative;

      &:hover {
        color: #444;
        background-color: #e5e5e5;
      }

      &.active {
        color: #000;
        background-color: white;
      }
    }

    @media (max-width: "700px") {
      width: 100%;
      display: block;
      min-height: 1px;

      a {
        display: inline-block;
        font-size: 12px;
        padding: 6px;
      }
    }
  }

  .gdpr-cm-cookie-list {
    float: left;
    font-size: 11pt;
    width: 74%;
    padding: 20px;
    overflow-y: auto;
    padding-bottom: 30px;

    p {
      line-height: 1.6;
    }

    .pretty {
      float: right;
      font-size: 12pt;
      width: 104px;
    }

    .gdpr-cm-enabled {
      color: #6cc04a;
      font-weight: bold;
      padding: 0 15px;

      @media (max-width: 500px) {
        padding: 0 10px;
      }
    }

    .gdpr-cm-disabled {
      font-weight: bold;
      padding-left: 9px;

      @media (max-width: 700px) {
        padding: 0 13px;
      }

      @media (max-width: 500px) {
        padding: 0 3px;
      }
    }

    table {
      background-color: transparent;
      border: 0;
      width: 100%;

      thead {
        tr {
          background-color: transparent;
          border: none;
        }

        th {
          font-size: 9pt;
          text-align: center;
          font-weight: bold;
          padding: 10px;
          background-color: #eee;
        }
      }

      tbody {
        td {
          border: 0;
          text-align: center;
          padding: 10px;
          border-bottom: 1px solid #ddd;
          font-size: 9pt;

          &.gdpr-cm-cookie-name {
            width: 25%;
          }

          &.gdpr-cm-cookie-provider {
            width: 25%;
          }

          &.gdpr-cm-cookie-purpose {
            width: 30%;
          }

          &.gdpr-cm-cookie-expiry {
            width: 20%;
          }
        }
      }
    }

    @media (max-width: 700px) {
      width: 100%;
      display: block;
      float: left;

      .gdpr-cm-enabled { font-size: 13px; }

      p { font-size: 12px; }
    }
  }

  .icon-nv-close:before {
    content: "\fdd0";
    font-family: "icomoon";
  }

  .icon-nv-done_all:before {
    content: "\fdd2";
    font-family: "icomoon";
  }
</style>