import Utils from '../lib/utils'
import { getWishlistItems, getOrder, getOfferOrder } from '../api/api'

/*
 * Loads the order and the wishlist from the API. Its called once when mini_cart app is mounted.
 * @event - gy:order-loaded event is triggered
 */
export const loadOrder = ({ commit, state, getters, dispatch, rootGetters }, orderId = null) => {
  let orderIdParam = orderId == null ? rootGetters['orders/getSelectedOrderId']() : orderId

  return getOrder(
    getters.apiPath,
    getters.stringifiedVersions('miniCart'),
    state.customAttributesMiniCart,
    getters.stringifiedOrderOptions,
    orderIdParam
  ).then((response) => {
    commit('setOrder', response.data.order)
    commit('setCoupon', response.data.coupon)
    commit('setUser', response.data.user)
    commit('orders/REPLACE_ORDER', response.data.order, { root: true } )
    dispatch('triggerEvent', 'gy::order-loaded')
  })
}

/*
 * Loads the WishlistItems from the API. Its called from action loadOrder
 * @event - gy:wishlist-loaded event is triggered
 */
export const loadWishlistItems = ({ commit, getters, dispatch }) => {
  if (getters.signedIn) {
    return getWishlistItems(getters.apiPath, getters.stringifiedVersions('wishlist')).then((response) => {
      let wishlist_items = response.data.wishlist_items

      if (wishlist_items != undefined) {
        commit('setWishlistItems', wishlist_items)
        dispatch('triggerEvent', 'gy::wishlist-loaded')
      }
    })
  }
}

/*
 * Loads the offer order from the API. Its called from action loadOfferOrder
 * @event - gy:offer-order-loaded event is triggered
 */
export const loadOfferOrder = ({ commit, state, getters, dispatch }) => {
  return getOfferOrder(
    getters.apiPath,
    getters.stringifiedVersions('offerOrder'),
    state.customAttributesMiniCart
  ).then((response) => {
    commit('setOfferOrder', response.data.offer_order)
    dispatch('triggerEvent', 'gy::offer-order-loaded')
  })
}

export const setRecentlyAdded = ({ commit }, payload) => {
  commit('setRecentlyAdded', payload)
}

export const setOrderTitle = ({ commit }, payload) => {
  commit('setOrderTitle', payload)
}

export const triggerEvent = (context, object) => {
  let message, type
  if (object) {
    if (typeof object == 'object') {
      type = object.type
      message = object.message
    } else {
      type = object
      message = ''
    }

    let event = new CustomEvent(type, {
      detail: {
        message: message,
        event: object.event,
        item: object.item,
        component: object.component,
        error: object.error,
        optionVariantId: object.optionVariantId,
        time: new Date()
      },
      bubbles: true,
      cancelable: true
    })

    Utils.defer('gyEvents', () => {
      setTimeout(function() {
        document.dispatchEvent(event)
      }, 40)
    })
  }
}

export const setHistory = (context, options) => {
  if (options.component) {
    context.state.history.push(options)
  }
}

export const openCreditCardModal = () => {
  // Do nothing. Used only for subscribing using subscribeAction from PayButton component
}
