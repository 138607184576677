<template>
  <a
    href="#"
    @click.prevent="openModal()"
  >
    {{ consentPreference.link_name }}
  </a>
</template>

<script>
import Vue from 'vue'
import VModal from 'vue-js-modal'
import ConsentsModal from './modal.vue'

export default Vue.component('ConsentsUpdateContainer', {
  props: ["linkValue"],
  methods: {
    getConsentPreferences() {
      return this.$http.get("consent_preferences", {
        params: {
          domain: this.domain,
          locale: window.locale || this.locale
        },
        headers: {
          "X-Authorization-Token": this.token
        } })
    },
    openModal() {
      let $this = this
      this.setDomainAndToken()

      this.getConsentPreferences().then(response => {
        let { consent_preference, site_consent_fields } = response.body

        $this.$modal.show(ConsentsModal, {
          consentPreference: consent_preference,
          siteConsentFields: site_consent_fields
        }, {
          height: "auto",
          width: 800,
          adaptive: true,
          scrollable: true,
          clickToClose: true,
          root: this.$root
        })
      })
    },
    setDomainAndToken() {
      if (window.gy_moad != undefined) {
        this.domain = window.gy_moad.domain
        this.token  = window.gy_moad.token

        if (window.gy_moad.locale != undefined) {
          this.locale = window.gy_moad.locale
        } else {
          this.locale = "el"
        }
      } else {
        this.domain = window.location.hostname
        this.token  = ''
        this.locale = "el"
      }
    }
  }
})
</script>