<template>
  <div class="quick-order-product-select inline">
    <MultiSelect
      :id="attrIdVal"
      ref="product_select2"
      label="code"
      track-by="id"
      :options="products"
      :placeholder="placeholderVal"
      :searchable="true"
      :internal-search="false"
      :allow-empty="false"
      :loading="isLoading"
      :limit="1"
      :options-limit="optionsLimit"
      :limit-text="limitText"
      select-label=""
      :selected-label="selectedLabel"
      :deselect-label="deselectedLabel"

      @search-change="findProduct"
      @select="select"
    >
      <span
        slot="noResult"
        class="text-sm"
      >
        {{ noResultVal }}
      </span>
      <span
        slot="noOptions"
        class="text-sm"
      >
        {{ noOptionsVal }}
      </span>

      <template
        slot="option"
        slot-scope="props"
      >
        <slot
          name="content"
          :option="props.option"
        />
      </template>
    </MultiSelect>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import NiceI18n from '../../lib/nice_i18n'

import MultiSelect from 'vue-multiselect'

export default {
  name: 'QuickOrderProductSelect',
  components: {
    MultiSelect
  },
  props: {
    placeholder: {
      type: String,
      default: undefined
    },
    selectedProductId: {
      type: Number,
      default: undefined
    },
    attrId: {
      type: Number,
      default: undefined
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    minLength: {
      type: Number,
      default: 3
    },
    per: {
      type: Number,
      default: 20
    },
    optionsLimit: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      products: [],
      productId: null,
      skuId: null,
      isLoading: false,
      previousRequest: null
    }
  },
  computed: {
    ...mapGetters([
      'apiPath',
      'siteLocalePath'
    ]),
    attrIdVal() {
      return this.attrId
    },
    placeholderVal() {
      return typeof(this.placeholder) == 'undefined' ? NiceI18n.t('saved_orders.product_select.placeholder') : this.placeholder
    },
    noResultVal() {
      return NiceI18n.t('saved_orders.product_select.no_products_found')
    },
    noOptionsVal() {
      return NiceI18n.t('saved_orders.product_select.empty_list')
    },
    hasAutofocus() {
      return Boolean(this.autofocus)
    },
    selectedLabel() {
      return NiceI18n.t('saved_orders.product_select.selected_label')
    },
    deselectedLabel() {
      return NiceI18n.t('saved_orders.product_select.deselected_label')
    }
  },
  mounted() {
    if (this.selectedProductId) {
      this.productId = this.selectedProductId

      if (this.hasAutofocus) {
        this.$refs.product_select2.$refs.search.focus()
      }
    }
  },
  methods: {
    limitText (count) {
      return `${NiceI18n.t('saved_orders.product_select.limit_text.and')} ${count} ${NiceI18n.t('saved_orders.product_select.limit_text.other_products')}`
    },
    select (option) {
      this.productId = option.product_id
      this.skuId = option.sku_id

      this.$emit('gy:saved-line-item-product-changed', {
        productId: this.productId,
        skuId: this.skuId
      })
    },
    async findProduct(query) {
      if (query && query.length >= this.minLength) {
        this.isLoading = true

        // Cancel previous request if it exists
        if (this.previousRequest) {
          this.previousRequest.cancel()
        }

        // Create a new cancellation token
        const source = axios.CancelToken.source()
        // Store the cancellation token
        this.previousRequest = source

        try {
          const res = await axios.get(`${this.siteLocalePath}/autocomplete/saved_order`,
            {
              params: {
                query: query,
                per: 20
              },
              cancelToken: source.token
            }
          )
          this.products = res.data.products
        } catch (error) {
          this.products = []

          if (!axios.isCancel(error)) {
            console.error('Error fetching products:', error)
          }
        } finally {
          this.isLoading = false
        }
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
  .quick-order-product-select  {
    .multiselect__input {
      padding: 5px;
      box-shadow: none !important;
      font-size: 14px;
    }

    .multiselect__placeholder {
      padding: 4px 5px 3px 5px;
      color: #222;
    }
  }
</style>
