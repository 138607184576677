<template>
  <div class="gy-gdpr-consents">
    <span
      v-for="field in screen.fields"
      :key="field.title"
      class="gy-gdpr-checkbox-wrapper"
    >
      <div class="checkbox-container pretty">
        <div class="state">
          <input
            :id="field.id"
            v-model="field.agree"
            type="checkbox"
            name="check"
            :aria-label="field.title | stripHTML"
          >
          <label
            :class="['consent-label', { 'required-consent': field.required }]"
            v-html="field.title"
          />
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.component('Consents', {
  props: ['type']
})
</script>


<style lang="scss">
  .required-consent p:first-child {
    &::before {
      content: " * ";
    }
  }

  .checkbox {
    &-container {
      &.pretty {
        .state {
          display: flex;
          position: relative;
          cursor: pointer;

          label {
            cursor: pointer;
            padding: 1px 5px !important;

            &::before,
            &::after { display: none; }

            p { margin: 0; }
          }

          input {
            height: 17px;
            width: 17px;
            border-radius: 3px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: 1px solid rgb(229, 226, 226);
            z-index: 1;
            cursor: pointer;
            opacity: unset;
            position: unset !important;
            margin: unset !important;
            padding: unset !important;
            box-sizing: border-box;
            flex: 0 0 17px;

            &:checked {
              &::before {
                content: '\2713';
                animation: bounce .4s ease;
                color: white;
                background-color: #5cb85c;
                position: absolute;
                left: 0;
                top: 0;
                width: 17px;
                height: 17px;
                line-height: 17px;
                padding-left: 3px;
                display: block;
                border-radius: 3px;
                box-sizing: border-box;
                padding-top: 1px;
              }
            }
          }
        }
      }
    }
  }

  span.consent-label a {
    z-index: 5;
    position: relative;
  }


  .pretty .state label {
    font-size: inherit;
    padding-left: 4px;
  }

  .gy-gdpr-checkbox-wrapper {
    font-size: 13px;
  }

  .gy-gdpr-consent-success,
  .gy-gdpr-consent-error {
    margin: 0.6em 0;
  }

  .gy-gdpr-consent-success {
    color: green;
  }

  .gy-gdpr-consent-error {
    color: red;
  }

  .gy-gdpr {
    display: inline-block;
  }

  .gy-gdpr-testing-enabled {
    position: relative;

    .gdpr-testing-label {
      display: inline-block;
      position: absolute;
      top: -23px;
      left: 10px;
      padding: 4px 6px;
      height: 23px;
      font-weight: bold;
      background: #f0f;
      color: white;
      font-size: 11px;
      border: 1px solid white;
      border-bottom: 0;
      z-index: 9999999;
    }
  }

@keyframes bounce {
  0%  { transform:scale(.25); opacity:.25 }
  50% { transform:scale(1.4); opacity:1.4 }
  100%{ transform:scale(1); opacity:1 }
}
</style>