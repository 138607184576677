<template>
  <a
    href="#"
    :class="{ active: isActive }"
    class="gdpr-cm-category-anchor"
    @click.prevent="select()"
  >
    <i
      v-if="!isActive"
      class="nv-circle-outline"
    />
    <i
      v-if="isActive"
      class="nv-circle"
    />
    {{ category.title }}
  </a>
</template>
<script>
import Vue from 'vue'
import { EventBus } from '../../lib/event_bus.js'

export default Vue.component('CookieCategory', {
  props: {
    category: {
      default: () => {
        return {}
      },
      type: Object
    }, 
    selectedCategory: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      active: null
    }
  },
  computed: {
    isActive() {
      if (this.selectedCategory == this.category)
        return true

      return this.active
    }
  },
  mounted() {
    EventBus.$on("cookieCategorySelected", this.deselect)
  },
  methods: {
    select() {
      EventBus.$emit("cookieCategorySelected", this.category)
      this.active = true
    },
    deselect(category) {
      this.active = (this.category == category)
    }
  }
})
</script>

<style lang="scss">
  .gdpr-cm-category-anchor {
    .nv-circle,
    .nv-circle-outline {
      border-radius: 50%;
      background-color: #09a501;
      height: 10px;
      width: 10px;
      line-height: 10px;
      margin-right: 10px;

      @media (max-width: 700px) {
        display: inline-block;
      }
    }

    .nv-circle-outline {
      border: 1px solid #09a501;
      background-color: unset;
    }
  }
</style>