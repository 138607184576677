import OrdersAPI from '../../api/orders'
import NiceI18n from '../../lib/nice_i18n'

const initialState = () => ({
  orders: [],
  selectedOrderId: null,
  productSelectedOrderIds: {}
});
const state = initialState();

const getters = {
  apiPath(state, getters, rootState, rootGetters) {
    return rootGetters.apiPath
  },

  summaryTotalQuantity(state) {
    return state.orders
      .map(order => order.total_quantity)
      .reduce((prev, curr) => prev + curr, 0)
  },

  numberOfOrders(state) {
    return state.orders.length
  },

  isTitleUnique: (state) => (title) => {
    return (state.orders.filter(order => order.title === title).length === 0)
  },

  isDeletable(state) {
    return state.orders.length > 1
  },

  hasOnlyTransientOrders(state) {
    return (state.orders.filter(order => order.id !== null).length === 0)
  },

  /*
   * Used by API calls (Example: createOrder API POST from product.vue) to
   * add a order_id parameter to the post request. Therefor adding products to
   * the correct order.
   */
  getSelectedOrderId: (state) => (productId = undefined) => {
    let stringifiedProductId = `${productId}`

    // TODO - there are probably more pages - not good to hard-code in nice-vue
    let orderContext = window.location.pathname.match(/\/orders\/([0-9]+)/)

    if (orderContext) {
      // 1st - context of an order - like order detail page
      // Example - do all order manipulation for that certain order
      return parseInt(orderContext[1])
    } else if (stringifiedProductId in state.productSelectedOrderIds) {
      // 2nd - context of an product - like product catalog or product detail
      // Example: add product to a CERTAIN order
      return state.productSelectedOrderIds[stringifiedProductId]
    } else {
      // 3rd - default selected context - use user selected order
      // Example: add product to the user-selected order
      return state.selectedOrderId
    }
  }
}

const triggerSuccessEvent = (dispatch, event_type_prefix, response) => {
  let type = `${event_type_prefix}-${response.data.error ? 'failed' : 'succeeded'}`
  let message = response.data.message ? response.data.message : NiceI18n.t("orders.store.generic_success")
  let item = response.data.order

  dispatch('triggerEvent', { type: type, message: message, item: item }, { root: true })
}

const triggerFailureEvent = (dispatch, event_type_prefix, error_message = null) => {
  let event_type = `${event_type_prefix}-failed`
  let event_message = `${NiceI18n.t("orders.store.generic_failure")}`
  if (error_message !== null) {
    event_message += ` (${error_message})`
  }
  dispatch('triggerEvent', { type: event_type, message: event_message }, { root: true })
}

const isOrderOpinionatedPage = () => {
  return window.location.pathname.match(/\/orders\/[0-9]+/) ||
    window.location.pathname.match(/\/order\/edit/) ||
    window.location.pathname.match(/\/order\/confirm/)
}

const actions = {
  /*
   * Loads all orders.
   *
   * Loads an array of user editable orders.
   * @fires gy:orders-loaded
   */
  loadOrders({ commit, state, getters, dispatch, rootState }) {
    return OrdersAPI.index(
      getters.apiPath,
      { shopping_list: true }
    ).then((response) => {
      commit('SET_ORDERS', response.data.orders)
      commit('UPDATE_SELECTED_ORDER_ID')

      if (!isOrderOpinionatedPage()) {
        dispatch('loadOrder', null, { root: true })
      }
    })
  },

  /*
   * Deletes a given order.
   *
   * Deletes an given order by a given id.
   * @fires gy:orders-delete-(succeeded|failed)
   *
   * @param {Object}    params    :id order id (mandatory)
   */
  deleteOrder({ commit, state, getters, dispatch }, params) {
    const eventTypePrefix = 'gy::orders-delete'
    return new Promise((resolve, reject) => {
      OrdersAPI.delete(
        getters.apiPath,
        params.id
      ).then((response) => {
        triggerSuccessEvent(dispatch, eventTypePrefix, response)
        if (response.data.error) {
          reject(response.data)
        } else {
          commit('REMOVE_ORDER', params.id)
          if (state.selectedOrderId === params.id) {
            commit('SET_ORDER_AS_SELECTED', state.orders[0].id)
            commit('UPDATE_SELECTED_ORDER_ID')
          }
          resolve(response.data)
        }
      }).catch((error) => {
        triggerFailureEvent(dispatch, eventTypePrefix, error.message)
        reject(error.message)
      })
    })
  },

  /*
   * Selects a given order.
   *
   * The selected order acts as the default order to receive added line_items,
   * in case no order was otherwise selected.
   * @fires gy::orders-select-(succeeded|failed)
   *
   * @param {Object}    params    :id order id (mandatory)
   */
  selectOrder({ commit, state, getters, dispatch }, params) {
    const eventTypePrefix = 'gy::orders-select'
    return new Promise((resolve, reject) => {
      OrdersAPI.update(
        getters.apiPath,
        params.id,
        { selected: true, shopping_list: true }
      ).then((response) => {
        triggerSuccessEvent(dispatch, eventTypePrefix, response)
        if (response.data.error) {
          reject(response.data)
        } else {
          if (!isOrderOpinionatedPage()) dispatch('loadOrder', params.id, { root: true })
          commit('SET_ORDER_AS_SELECTED', params.id)
          commit('UPDATE_SELECTED_ORDER_ID')
          resolve(response.data)
        }
      }).catch((error) => {
        triggerFailureEvent(dispatch, eventTypePrefix, error.message)
        reject(error.message)
      })
    })
  },

  /*
   * Creates a new order.
   *
   * Creates a new blank order. You can set a title. Otherwise it defaults to
   * the i18n default shopping list title.
   * @fires gy::orders-create-(succeeded|failed)
   *
   * @param {Object}    params    :title  order title (optional)
   */
  createOrder({ commit, state, getters, dispatch }, params) {
    const eventTypePrefix = 'gy::orders-create'
    return new Promise((resolve, reject) => {
      OrdersAPI.create(
        getters.apiPath,
        { shopping_list: true, ...params }
      ).then((response) => {
        triggerSuccessEvent(dispatch, eventTypePrefix, response)
        if (response.data.error) {
          reject(response.data)
        } else {
          commit('ADD_ORDER', response.data.order)
          resolve(response.data)
        }
      }).catch((error) => {
        triggerFailureEvent(dispatch, eventTypePrefix, error.message)
        reject(error.message)
      })
    })
  },

  /*
   * Updates an order.
   *
   * Updates a given order - and sets :title, :selected.
   * @fires gy::orders-update-(succeeded|failed)
   *
   * @param {Object}    params    :id         order id (mandatory)
   *                              :title      order title (optional)
   *                              :selected   is order selected? (optional)
   */
  updateOrder({ commit, state, getters, dispatch, rootState }, params) {
    const options = { triggerSuccessEvent: true }
    if (params['triggerSuccessEvent'] !== undefined) {
      options['triggerSuccessEvent'] = params['triggerSuccessEvent']
    }

    const eventTypePrefix = 'gy::orders-update'
    return new Promise((resolve, reject) => {
      OrdersAPI.update(
        getters.apiPath,
        params.id,
        { shopping_list: true, ...params }
      ).then((response) => {
        if (options['triggerSuccessEvent'] === true) {
          triggerSuccessEvent(dispatch, eventTypePrefix, response)
        }
        if (response.data.error) {
          reject(response.data)
        } else {
          commit('UPDATE_ORDER', params)
          commit('UPDATE_SELECTED_ORDER_ID')
          resolve(response.data)
        }
      }).catch((error) => {
        triggerFailureEvent(dispatch, eventTypePrefix, error.message)
        reject(error.message)
      })
    })
  },

  /*
   * Duplicates a given order.
   *
   * Duplicates an given order by a given id.
   * @fires gy:orders-duplicate-(succeeded|failed)
   *
   * @param {Object}    params    :id order id (mandatory)
   */
  duplicateOrder({ commit, state, getters, dispatch }, params) {
    const eventTypePrefix = 'gy::orders-duplicate'
    return new Promise((resolve, reject) => {
      OrdersAPI.duplicate(
        getters.apiPath,
        params.id
      ).then((response) => {
        triggerSuccessEvent(dispatch, eventTypePrefix, response)
        if (response.data.error) {
          reject(response.data)
        } else {
          commit('ADD_ORDER', response.data.order)
          resolve(response.data)
        }
      }).catch((error) => {
        triggerFailureEvent(dispatch, eventTypePrefix, error.message)
        reject(error.message)
      })
    })
  },

  /*
   * Sets a pre-seleceted order for a product (see mutation).
   */
  updateProductSelectedOrder({ state, commit }, params) {
    commit('SET_PRODUCT_SELECTED_ORDER_ID', params)
  },

  /*
   * Resets state - used on user logout.
   */
  reset({ commit }) {
    commit('RESET');
  }
}

const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders
  },

  /*
  * Mark a given order as selected.
  *
  * Resets selected attribute on all orders to false - except for the order
  * which matches the given order_id.
  *
  * @params {Number}    selectedOrderId     the order to become selected
  */
  SET_ORDER_AS_SELECTED(state, selectedOrderId) {
    state.orders.forEach(order => order.selected = (order.id === selectedOrderId))
  },

  UPDATE_SELECTED_ORDER_ID(state) {
    // TODO - there are probably more pages - not good to hard-code in nice-vue
    let orderContext = window.location.pathname.match(/\/orders\/([0-9]+)/)

    if (orderContext) {
      state.selectedOrderId = getters.getSelectedOrderId()()
    }
    else {
      let selectedOrders = state.orders.filter(order => order.selected)

      if (selectedOrders.length === 1) {
        state.selectedOrderId = selectedOrders[0].id
      }
    }
  },

  REMOVE_ORDER(state, destroyedOrderId) {
    state.orders = state.orders.filter(order => order.id != destroyedOrderId)
  },

  ADD_ORDER(state, order) {
    state.orders = [...state.orders, order]
  },

  /*
  * Updates the attributes of a given order.
  *
  * @param {Object}    params    'id' order id (mandatory)
  *                              'title', 'selected' (optional)
  */
  UPDATE_ORDER(state, params) {
    let order_id = params['id']
    let order = state.orders.filter(order => order.id === order_id).shift()
    if (order !== undefined) {
      for (let [key, value] of Object.entries(params)) { order[key] = value }
    }
  },

  /*
  * Replaces an order in the orders array.
  *
  * Looks into the orders array and upon matching order_id replaces the one
  * in the orders array with the given one.
  *
  * @param {Object}    orderToReplace     the replacement order
  */
  REPLACE_ORDER(state, orderToReplace) {
    state.orders = state.orders.map(order => order.id === orderToReplace.id ? orderToReplace : order)
  },

  /*
   * Sets a pre-seleceted order for a product.
   *
   * Is used to select a certain order before hitting the "add to cart" button.
   * Scenario: 1) User has three Shopping Lists with IDs 1,2, and 3.
   *           2) The global selected default is ID 1
   *           3) For a certain product A the user selects Shopping List with ID 2
   *           4) When hitting the "Add to Cart"-Button the product A will be
   *              added to order with ID 2
   *           5) When hitting the "Add ti Cart"-Button for any other product, it
   *              will be added to the global selected shopping list with ID 1
   *
   * @param Number    productId     product for which a certain order is selected
   * @param Number    orderId       product pre-selected order
   */
  SET_PRODUCT_SELECTED_ORDER_ID(state, params) {
    if (params.productId) {
      params.orderId ?
        state.productSelectedOrderIds[params.productId] = params.orderId :
        delete state.productSelectedOrderIds[params.productId]
    }
  },

  /*
   * Resets all orders - used on user logout
   */
  RESET(state) {
    state = initialState()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
