import Vue from 'vue'

function removeThousandsSeparator(value) {
  if ((value.match(/,/g) || []).length > 1) {
    // Remove commas (they're thousands separators)
    return value.replace(/,/g, '')
  } else if ((value.match(/\./g) || []).length > 1) {
    // Remove dots (they're thousands separators)
    return value.replace(/\./g, '')
  }

  return value
}

function handleDecimalSeparator(value) {
  if (value.indexOf(',') > value.indexOf('.')) {
    // If comma comes after dot, it's European-style
    return value.replace(/\./g, '').replace(',', '.')
  } else if (value.indexOf(',') > -1) {
    // If there is a comma and no dot before, it's European-style
    return value.replace(/,/g, '')
  }

  return value
}

Vue.filter('currencyToUnits', (value) => {
  if (!value) return 0

  value = value.replace(/[^\d.,]/g, '').trim()
  value = removeThousandsSeparator(value)
  value = handleDecimalSeparator(value)

  // Convert to number and handle NaN cases
  const numberValue = parseFloat(value)
  if (isNaN(numberValue)) return 0

  // Round to two decimal places and multiply by 100
  return Math.round(numberValue * 100)
})
