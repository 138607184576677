export default {
  props: {
    versions: {
      type: Object | String, // "{ "width": 385, "height": 391 }"
      default: null
    }
  },
  computed: {
    foundVersions() {
      let versions;
      
      if (this.$options.propsData.versions) {
        // new way
        versions = this.$options.propsData.versions
      } else {
        // old way - TODO: check if we can deprecate
        versions = this.$el.attributes.versions?.value || {}
      }
      
      return versions
    },
    stringifiedVersions() {
      if (this.versions) {
        try {
          const parsedVersions = (typeof this.versions === 'string')
            ? JSON.parse(this.versions)
            : this.versions

          return JSON.stringify(parsedVersions);
        } catch (error) {
          console.error('Error parsing versions:', error);
        }
      }

      return undefined
    }
  }
}