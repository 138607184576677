/**
 * Automatically imports all the modules and exports as a single module object
 * looks for the .store.js file extension in this directory ./*.store.js
 *
 * Builds the module name from file name skipping the .store.js extension.
 * Example: ./module1.store.js (Module File) => module1 (Module Name)
 *
 * see webpack "require.context"
 * https://webpack.js.org/guides/dependency-management/#require-context
 *
 * adapted from
 * https://medium.com/locale-ai/architecting-vuex-store-for-large-scale-vue-js-applications-24c36137e251
 */
const requireModule = require.context('.', false, /\.store\.js$/)
const modules = {}

requireModule.keys().forEach((filename) => {
  const moduleName = filename.replace(/(\.\/|\.store\.js)/g, '')

  modules[moduleName] = requireModule(filename).default || requireModule(filename)
})

export default modules
